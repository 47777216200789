import { useMutation, useQuery } from '@tanstack/react-query'
import { QuizAPIClient } from '../api-client/quiz-api-client.ts'

export const useQuiz = () => {
  const SourcesIncomeQuery = (locale: string) =>
    useQuery({
      queryKey: ['sourceIncome'],
      queryFn: () => QuizAPIClient.getSourcesIncome(locale)
    })
  const AccountReasonsQuery = (locale: string) =>
    useQuery({
      queryKey: ['accountReason'],
      queryFn: () => QuizAPIClient.getAccountReasons(locale)
    })
  const PaymentsUsageQuery = (locale: string) =>
    useQuery({
      queryKey: ['paymentUsage'],
      queryFn: () => QuizAPIClient.getPaymentsUsage(locale)
    })
  function useCreateQuizMutation() {
    return useMutation({
      mutationFn: QuizAPIClient.create
    })
  }

  function useCheckIbanQuizMutation() {
    return useMutation({
      mutationFn: QuizAPIClient.checkIban
    })
  }
  function useIsValidatedIbanQuizMutation() {
    return useMutation({
      mutationFn: QuizAPIClient.isValidatedIban
    })
  }
  return {
    SourcesIncomeQuery,
    AccountReasonsQuery,
    PaymentsUsageQuery,
    useCreateQuizMutation,
    useCheckIbanQuizMutation,
    useIsValidatedIbanQuizMutation
  }
}
