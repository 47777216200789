export type SendEmailCodeResponse = {
  success: boolean
  data?: {
    cypher: string
  }
  message: string
}

export type ValidationEmailCodeResponse = {
  success: boolean
  data: string
  message: string
}

export type ValidationEmailCodeParams = {
  cypher: string
  codeEmail: string
  actionType: string
}

export enum ChangePasswordErrorCode {
  WrongOTP = 'wrong_OTP',
  WrongPassword = 'wrong_password'
}

export type ChangePasswordParams = {
  password: string
}

export type ChangePasswordResponse = {
  success: boolean
  errorCode?: ChangePasswordErrorCode
}

export type TryConnectionParams = {
  email: string
  password: string
  codeOtp: string
}

export type TryConnectionResponse = {
  success: boolean
  data?: string
}

export type AddTraceResponse = {
  action: string
  id: string
}

export type UpdateTraceParams = {
  id: number
  success: boolean
  web_authn_signing_action_id: any
  user_otp_input: string | null
}

export type SensitiveEmail = {
  email: string
  action_type?: string
}

export type SendSensitiveEmailCodeResponse = {
  success: boolean
  data?: {
    uuid: string
  }
  message: string
}

export type ValidationSensitiveEmailCodeParams = {
  uuidSensitiveActionValidation: string
  codeEmail: string
  actionType: string
}

export type ValidationSensitiveEmailCodeResponse = {
  success: boolean
  data?: {
    is_validated: boolean
  }
  message: string
}
