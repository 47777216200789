import { getRoute } from '@/app/olkypass/utils/routes'
import { Base, CardTitle } from '@/atoms-react/text/Text.react'
import { AnimatedShield } from '@/components/animations/shield/index.react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { KYCStatusType } from '@/features/kyc/types'
import { Routes } from '@/features/router'
import { useUser } from '@/features/user/hooks/use-user'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export type OlkypassCardProps = {
  CTA?: boolean
  status: KYCStatusType
  verifyButton?: boolean
  small?: boolean
}

import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { useMemo, useState } from 'react'
import { ModalKYCIdentity } from '@/molecules/modal-kyc-identity/index.react'
import { ModalOlkyPass } from '@/molecules/modal-olkypass/index.react'
import { Button } from '@/atoms-react/button/Button.react'
import { Themes } from '@/types/theme'

export const OlkypassCard = ({
  status,
  CTA = true,
  verifyButton = false,
  small = false
}: OlkypassCardProps) => {
  const { useUserProfile } = useUser()
  const { data: user } = useUserProfile()

  const [modalIdentity, setModalIdentity] = useState<boolean>(false)

  const { t } = useTranslation(['pass'])
  const navigate = useNavigate()

  const { company } = useCompanyContext()

  // Use States et variables for the modal
  const modalOlkyPassAllSteps = [
    {
      id: 0,
      title: t('olkypass.mainPage.olkypass.modalOlkyPass.olkypass_passport'),
      description: t('olkypass.mainPage.olkypass.modalOlkyPass.passport_description'),
      imageSource: ''
    },
    {
      id: 1,
      title: t('olkypass.mainPage.olkypass.modalOlkyPass.status_declarative'),
      description: t('olkypass.mainPage.olkypass.modalOlkyPass.declarative_description'),
      imageSource: '/images/olkypass/olkyPassDeclarative.svg'
    },
    {
      id: 2,
      title: t('olkypass.mainPage.olkypass.modalOlkyPass.status_verified'),
      description: t('olkypass.mainPage.olkypass.modalOlkyPass.verified_description'),
      imageSource: '/images/olkypass/olkyPassVerified.svg'
    },
    {
      id: 3,
      title: t('olkypass.mainPage.olkypass.modalOlkyPass.status_authenticated'),
      description: t('olkypass.mainPage.olkypass.modalOlkyPass.authenticated_description'),
      imageSource: '/images/olkypass/olkyPassAuthenticated.svg'
    }
  ]
  const [currentOlkyPassModalStep, setCurrentOlkyPassModalStep] = useState(0)
  const [isOlkyPassModalOpen, setIsOlkyPassModalOpen] = useState<boolean>(false)

  const filteredSteps = useMemo(() => {
    if (status === KYCStatusType.AUTHENTICATED) {
      return [modalOlkyPassAllSteps[0], modalOlkyPassAllSteps[3]]
    } else if (status === KYCStatusType.VERIFIED) {
      return modalOlkyPassAllSteps.filter((modalOlkyPassStatus) => modalOlkyPassStatus.id !== 1)
    }
    return modalOlkyPassAllSteps
  }, [status])

  const handleStepChange = () => {
    if (currentOlkyPassModalStep < filteredSteps.length - 1) {
      setCurrentOlkyPassModalStep(currentOlkyPassModalStep + 1)
    }
  }

  const closeModal = () => {
    setIsOlkyPassModalOpen(false)
    setCurrentOlkyPassModalStep(0)
  }

  const onVerifyInfos = () => {
    if (status === KYCStatusType.VERIFIED) {
      // TODO: Add code to verify address
    } else if (status === KYCStatusType.DECLARATIVE) {
      setModalIdentity(true)
    }
    setIsOlkyPassModalOpen(false)
    setCurrentOlkyPassModalStep(0)
  }

  return (
    <>
      <ModalOlkyPass
        isOpen={isOlkyPassModalOpen}
        imageUrl={filteredSteps[currentOlkyPassModalStep].imageSource}
        title={filteredSteps[currentOlkyPassModalStep].title}
        description={filteredSteps[currentOlkyPassModalStep].description}
        totalSteps={filteredSteps.length}
        currentStep={currentOlkyPassModalStep}
        onStepChange={handleStepChange}
        onClose={closeModal}
        onUnderstandButtonClick={closeModal}
        onVerify={onVerifyInfos}
      />
      <div
        className={twMerge(
          "rounded-sm w-full h-full text-center md:text-left bg-[url('/images/olkypass/kypass-card-background.png')] bg-cover bg-center",
          CTA && 'hover:cursor-pointer',
          status === KYCStatusType.LOADING && 'bg-gradient-to-b from-gray-500 to-gray-500/50',
          small ? 'min-h-24' : 'min-h-48'
        )}
        onClick={() => {
          if (!CTA) return
          navigate(getRoute(Routes.account_identity))
        }}
      >
        {status !== KYCStatusType.LOADING && (
          <div
            className={twMerge(
              'rounded-sm w-full h-full',
              status === KYCStatusType.VERIFIED &&
                'bg-gradient-to-b from-lime-600 to-lime-600/50 hover:bg-gradient-to-b',
              status === KYCStatusType.VERIFIED &&
                CTA &&
                ' hover:from-lime-500 hover:to-lime-500/50',
              status === KYCStatusType.NEUTRAL &&
                'bg-gradient-to-b from-orange-600 to-orange-600/50',
              status === KYCStatusType.NEUTRAL &&
                CTA &&
                'hover:bg-gradient-to-b hover:from-orange-500 hover:to-orange-500/50',
              status === KYCStatusType.DECLARATIVE && 'bg-gradient-to-b from-red-700 to-red-700/50',
              status === KYCStatusType.DECLARATIVE &&
                CTA &&
                'hover:bg-gradient-to-b hover:from-red-600 hover:to-red-600/50 ',
              small ? 'min-h-24' : 'min-h-48'
            )}
          >
            <div className="px-6 py-4 flex flex-col-reverse md:flex-row md:gap-x-8 items-center justify-between">
              <div>
                {!small && (
                  <div
                    className="flex items-center justify-center md:justify-start gap-x-2 mb-4 mt-4 md:mt-0 cursor-pointer text-white hover:text-white/80"
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsOlkyPassModalOpen(true)
                    }}
                  >
                    <Base className="text-inherit font-semibold">
                      {t('olkypass.general.title')}
                      {[
                        company ? company.legal_name + ' - ' : '',
                        user?.naturalPerson.FirstName,
                        user?.naturalPerson.LastName
                      ]
                        .filter(Boolean)
                        .join(' ')}
                    </Base>
                    <InformationCircleIcon className="text-inherit h-6 w-6" />
                  </div>
                )}

                <div className={twMerge(small ? 'min-h-auto' : 'min-h-28')}>
                  <CardTitle className="text-white">
                    {status === KYCStatusType.AUTHENTICATED &&
                      t('olkypass.level.authenticated_title')}
                    {status === KYCStatusType.VERIFIED && t('olkypass.level.verified_title')}
                    {status === KYCStatusType.NEUTRAL && t('olkypass.level.neutral_title')}
                    {status === KYCStatusType.DECLARATIVE && t('olkypass.level.declarative_title')}
                  </CardTitle>
                  <Base className="mt-1 mb-3 text-white/90">
                    {status === KYCStatusType.AUTHENTICATED &&
                      t('olkypass.level.authenticated_label')}
                    {status === KYCStatusType.VERIFIED && t('olkypass.level.verified_label')}
                    {status === KYCStatusType.NEUTRAL && t('olkypass.level.neutral_label')}
                    {status === KYCStatusType.DECLARATIVE && t('olkypass.level.declarative_label')}
                  </Base>
                  {verifyButton && status === KYCStatusType.DECLARATIVE && (
                    <Button
                      onClick={() => setModalIdentity(true)}
                      theme={Themes.white}
                      className="border-none text-blue-500"
                    >
                      <span className="flex space-x-2 items-center justify-center z-10 overflow-hidden whitespace-nowrap text-ellipsis">
                        <span className="w-max truncate">
                          {t('olkypass.mainPage.button.completePass')}
                        </span>
                      </span>
                    </Button>
                  )}
                </div>
              </div>
              {/* Shield*/}
              {!small && (
                <div>
                  {status === KYCStatusType.AUTHENTICATED && <AnimatedShield percentage={100} />}
                  {status === KYCStatusType.VERIFIED && <AnimatedShield percentage={75} />}
                  {status === KYCStatusType.NEUTRAL && <AnimatedShield percentage={50} />}
                  {status === KYCStatusType.DECLARATIVE && <AnimatedShield percentage={50} />}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <ModalKYCIdentity
        onClose={() => {
          sessionStorage.removeItem('DocumentKsyc')
          setModalIdentity(false)
        }}
        open={modalIdentity}
      />
    </>
  )
}
