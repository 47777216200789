import { Button } from '@/atoms-react/button/Button.react'
import Image from '@/atoms-react/image/Image.react'
import { Base, Title } from '@/atoms-react/text/Text.react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { useUser } from '@/features/user/hooks/use-user'
import { formatNumber } from '@/features/utils/functions'
import { getWebBankingUrl } from '@/features/utils/web-banking-url'
import { ModalIbanAccount } from '@/molecules/modal-iban-account/index.react'
import { ModalKYCIdentity } from '@/molecules/modal-kyc-identity/index.react'
import { Themes } from '@/types/theme'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

// type DiscoverOlkyCardProps = {
//   className?: string
// } & React.ComponentPropsWithoutRef<'div'>

export const DiscoverOlkyCard = () => {
  const olkyCardsClassName =
    'relative overflow-hidden w-full md:max-w-xs h-64 md:h-56 rounded-sm 2xl:h-auto'
  const olkyCardsClassName2 = 'w-full h-full p-4 md:p-6 flex justify-center'
  const baseTextClassName = 'text-white font-semibold -mt-2 z-10'

  const { t } = useTranslation(['pass'])
  const { id: companyId } = useCompanyContext()

  //const { status } = useKYCContext()
  const { useUserProfile } = useUser()
  const { data: user } = useUserProfile()

  const [modalIdentity, setModalIdentity] = useState<boolean>(false)

  const [isModalIbanOpen, setIsModalIbanOpen] = useState<boolean>(false)

  const openKYCModal = () => {
    setModalIdentity(true)
    setIsModalIbanOpen(false)
  }
  useEffect(() => {
    if (sessionStorage.getItem('DocumentKsyc')) {
      const data = JSON.parse(sessionStorage.getItem('DocumentKsyc') as string)
      if (data.quizIbanStep > 0) setIsModalIbanOpen(true)
    }
  }, [])
  const redirectToBankAccount = () => {
    let email = user?.naturalPerson.AliasEmail.find((email) => email.Actif === true)?.Mail
    if (!email && user?.naturalPerson?.AliasEmail?.length > 0) {
      email = user?.naturalPerson.AliasEmail[0].Mail
    }
    const url = getWebBankingUrl(email)
    const success = window.open(url, '_blank')
    if (!success) document.location = url
  }
  return (
    <>
      <ModalIbanAccount
        isOpen={isModalIbanOpen}
        onClose={() => {
          sessionStorage.clear()
          setIsModalIbanOpen(false)
        }}
        openKYCModal={openKYCModal}
      />
      {!user?.naturalPerson.olkyAccount?.iban && !companyId && (
        <>
          <ModalKYCIdentity onClose={() => setModalIdentity(false)} open={modalIdentity} />
          <div
            className={twMerge(
              olkyCardsClassName,
              'w-full hover:opacity-75 relative overflow-hidden bg-cover bg-center hover:cursor-pointer bg-[url("/images/olkypass/olkyPayPro.png")] '
            )}
            onClick={() => setIsModalIbanOpen(true)}
          >
            <div className="absolute flex h-full w-full gap-x-3 z-0 items-center justify-center">
              <div className="rounded-sm overflow-hidden">
                <Image src="/images/olkypay/cards/particular-card.png" alt="Individual card" />
              </div>
              <div className="rounded-sm overflow-hidden">
                <Image src="/images/olkypay/cards/business-card.png" alt="Business card" />
              </div>
            </div>

            <div className={`${olkyCardsClassName2} flex-col items-center justify-between`}>
              <Base className={twMerge(baseTextClassName, 'text-center')}>
                {t('olkypass.general.olky_account_conso')}
              </Base>
              <Button
                size="md"
                theme={Themes.white}
                onClick={() => setIsModalIbanOpen(true)}
                iconPosition="before"
                className="border-none text-blue-500 mt-4 hover:bg-white"
              >
                {t('olkypass.mainPage.olkypass.bloc2.btn')}
              </Button>
            </div>
          </div>
        </>
      )}
      {user?.naturalPerson.olkyAccount && user?.naturalPerson.olkyAccount.iban && !companyId && (
        <div
          className={twMerge(
            'w-full rounded-sm relative overflow-hidden bg-cover bg-center',
            companyId
              ? "bg-[url('/images/olkypass/olkyPayPro.png')]"
              : "bg-[url('/images/olkypass/olkyPayConso.png')]"
          )}
        >
          {/* Gradient behind the card for consumer card*/}
          {!companyId && (
            <div className="absolute w-full h-full bg-gradient-to-l from-blue-700/30 to-blue-700-0 z-0" />
          )}
          <div className="absolute hidden md:block w-64 -right-6 top-1/2 transform -translate-y-1/2">
            {companyId ? (
              <div className="rounded-sm overflow-hidden">
                <Image src="/images/olkypay/cards/business-card.png" alt="Business card" />
              </div>
            ) : (
              <div className="rounded-sm overflow-hidden">
                <Image src="/images/olkypay/cards/particular-card.png" alt="Individual card" />
              </div>
            )}
          </div>
          <div className="w-full h-full p-4 md:p-6 flex flex-col">
            <Base className={twMerge(baseTextClassName, 'mb-2')}>
              {' '}
              {companyId
                ? t('olkypass.general.olky_account_pro')
                : t('olkypass.general.olky_account_conso')}
            </Base>
            <Title className="text-white mb-6 w-max z-0 rounded-xl backdrop-blur">
              {formatNumber(
                parseFloat(
                  user.naturalPerson.olkyAccount.balance.replaceAll(' ', '').replaceAll(',', '.')
                )
              )}{' '}
              €
            </Title>
            <div className="mt-auto flex-1 flex items-end">
              <Button
                onClick={redirectToBankAccount}
                theme={Themes.white}
                className="text-blue-500 w-full md:w-max border-none"
                icon={ArrowTopRightOnSquareIcon}
              >
                {t('olkypass.mainPage.olkypass.bloc2.btn_access_olkypay')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
