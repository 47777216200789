'use client'
import { useState } from 'react'
import { motion } from 'framer-motion'
import { Base, CardTitle } from '@/atoms-react/text/Text.react'
import { KypayInstrumentIcon } from '@/atoms-react/crypto-icon/KypayInstrumentIcon.react.tsx'

type Card = {
  id: number
  title: string
  content: React.ReactNode
  children?: React.ReactNode
}

export const CardStack = ({
  items,
  offset,
  scaleFactor
}: {
  items: Card[]
  offset?: number
  scaleFactor?: number
}) => {
  const CARD_OFFSET = offset || 10
  const SCALE_FACTOR = scaleFactor || 0.06
  const [cards] = useState<Card[]>(items)

  /*const goToPreviousCard = () =>
    setCards((prevCards: Card[]) => {
      const newArray = [...prevCards] // create a copy of the array
      newArray.unshift(newArray.pop()!) // move the last element to the front
      return newArray
    })*/

  /* const goToNextCard = () =>
    setCards((prevCards: Card[]) => {
      const newArray = [...prevCards] // create a copy of the array
      newArray.push(newArray.shift()!) // move the first element to the back
      return newArray
    })
*/
  //const backgroundColors = ['bg-blue-500', 'bg-blue-600', 'bg-blue-700']

  return (
    <div className="relative h-56 md:h-48 w-full max-w-lg min-w-40">
      {cards.map((card, index) => {
        // const backgroundColor = backgroundColors[index % backgroundColors.length]
        return (
          <motion.div
            key={card.id}
            className={`absolute border-2 border-slate-50 dark:border-slate-700 h-56 w-full max-w-lg md:h-48 rounded-sm mt-5 xl:mt-0 px-6 py-4 flex flex-col overflow-hidden overflow-y-auto`}
            style={{
              transformOrigin: 'top center'
            }}
            animate={{
              top: index * -CARD_OFFSET,
              scale: 1 - index * SCALE_FACTOR, // decrease scale for cards that are behind
              zIndex: cards.length - index // decrease z-index for the cards that are behind
            }}
          >
            <div className="z-10 flex-grow">
              <div>
                <CardTitle className="dark:text-white mb-3">{card.title}</CardTitle>
                <Base className="dark:text-white">{card.content}</Base>
              </div>
            </div>
            {card.children
              ? card.children // Render the children as a React Node if it exists
              : null}

            {/* Buttons to change card */}
            {/*<div className="sticky bottom-0 left-0 right-0 flex gap-x-3 justify-center items-center mb-4 z-10">*/}
            {/*  <ButtonIcon*/}
            {/*    size="md"*/}
            {/*    theme={Themes.white}*/}
            {/*    className="rounded-full bg-blue-900 flex text-white items-center justify-center cursor-pointer"*/}
            {/*    element={ChevronLeftIcon}*/}
            {/*    onClick={goToPreviousCard}*/}
            {/*  />*/}
            {/*  <ButtonIcon*/}
            {/*    size="md"*/}
            {/*    theme={Themes.white}*/}
            {/*    className="rounded-full bg-blue-900 flex text-white items-center justify-center cursor-pointer"*/}
            {/*    element={ChevronRightIcon}*/}
            {/*    onClick={goToNextCard}*/}
            {/*  />*/}
            {/*</div>*/}

            <KypayInstrumentIcon className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-0" />
          </motion.div>
        )
      })}
    </div>
  )
}
