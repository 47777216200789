import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react'
import InputDate from '@/atoms-react/input/InputDate.react'
import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import { DeclarativeBirth } from '@/features/flow/types'
import { AddressCreate } from '@/features/user/types'
import { FormAddAddress } from '@/molecules/form-add-address/index.react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FlowHeader } from '../../../../flow-header.react'
import _ from 'lodash'
import { DateTime } from 'luxon'

interface DeclarativeIdentityBirthProps extends LayoutBaseProps {
  onValidated?: (output: DeclarativeIdentityBirthOutput) => void
  personNationality?: string[]
}

export type DeclarativeIdentityBirthOutput = DeclarativeBirth

export function isDeclarativeBirthValid(data: DeclarativeBirth | null | undefined) {
  if (!data) return false
  return !!data.birth && !!data.cityOfBirth && !!data.countryCode
}

export function isBirthDateValid(date?: Date | null) {
  if (!date) {
    return false
  }
  const min = DateTime.now().minus({ years: 18 })
  if (min < DateTime.fromJSDate(date)) {
    return false
  }
  return true
}

function DeclarativeIdentityBirth({
  onValidated = () => {},
  personNationality
}: DeclarativeIdentityBirthProps) {
  const { t } = useTranslation(['pass'])
  const [data, setData] = useState<DeclarativeBirth>({})
  const [addressValid, setAddressValid] = useState(false)

  function execute() {
    if (!isDeclarativeBirthValid(data) || !addressValid) {
      toast.error(t('flow.id_control.error.must_enter_fields'))
      return
    }
    if (!isBirthDateValid(data.birth)) {
      toast.error(t('flow.id_control.error.must_be_18_plus'))
      return
    }

    onValidated(data)
  }
  return <>
    <Column className="justify-between">
      <div className="mb-12">
        <FlowHeader
          title={t('flow.id_control.signup.declarative_identity.label.two_title')}
          description={t('flow.id_control.signup.declarative_identity.label.two_description')}
        />
        {/*max={DateTime.now().minus({ years: 18 }).toFormat('yyyy-MM-dd')}*/}
        <InputDate
          autoComplete="bday"
          value={data?.birth ?? null}
          label={t('flow.id_control.input.birth_date_label')}
          placeholder={t('flow.id_control.input.birth_date_placeholder')}
          onChange={date => {
            if (date && !Array.isArray(date)) {
              setData(data => ({ ...data, birth: date }))
            } else if (date === null) {
              setData(data => ({ ..._.omit(data, 'birth') }))
            }
          }}
        />
        <FormAddAddress
          personNationality={personNationality}
          autoFocus
          fields={{
            tag: { visible: false },
            address: { visible: false },
            city: {
              visible: true,
              label: t('flow.id_control.input.city_of_birth_label')
            },
            zip: {
              visible: false,
              required: personNationality && personNationality[0] === 'FR' ? true : false
            },
            country: {
              visible: true,
              label: t('flow.id_control.input.country_of_birth_label')
            }
          }}
          address={{
            address: '',
            zip: data.zip ?? '',
            country: parseInt(data.countryCode ?? '-1'),
            city: data.cityOfBirth ?? '',
            tag: 'Résidence principale'
          }}
          onChange={(address: AddressCreate, valid) => {
            setAddressValid(valid)
            setData(data => ({
              ...data,
              cityOfBirth: address.city,
              zip: address.zip,
              countryCode: (address.country ?? '').toString()
            }))
          }}
          countryfirst
          className={'mt-0 mb-0'}
        />
      </div>
      <div className="shrink-0 flex flex-col items-center">
        <DotsNavigation total={5} current={1} />
        <Button
          shortcut="enter"
          onClick={() => execute()}
          icon={ChevronRightIcon}
          className="mt-4"
        >
          {t('flow.id_control.input.continue')}
        </Button>
      </div>
    </Column>
  </>
}

export default DeclarativeIdentityBirth
