import { useTranslation } from 'react-i18next'
import { SectionTitleSmall } from '@/atoms-react/text/Text.react.tsx'
import { Button } from '@/atoms-react/button/Button.react.tsx'
import { Themes } from '@/types/theme.ts'
import { useEffect, useState } from 'react'
import { Alert, AlertType } from '@/atoms-react/alert/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react'
import { AddressCreate, FullCountry, NaturalPersonDTO, UserType } from '@/features/user/types'
import { convert_naturalPerson_DTO } from '@/features/user/converters'
import { FormAddAddress } from '@/molecules/form-add-address/index.react'
import { StepsSettings } from '@/features/user/quiz/types'
import Loader from '@/atoms-react/loader/Loader.react'
const getDefaultAddressData = () => ({
  address: '',
  zip: '',
  country: null,
  city: '',
  tag: ''
})
export type nifValidationProps = {
  setStep: (next: string) => void
  stepSettings: StepsSettings
  user: UserType
  nifValidation: (country: string, user: NaturalPersonDTO) => void
  countries:
    | {
        countries: FullCountry[]
      }
    | undefined
}
export const NifVerificationStep = ({
  setStep,
  stepSettings,
  user,
  nifValidation,
  countries
}: nifValidationProps) => {
  const { t } = useTranslation(['pass'])
  const [userForm, setUserForm] = useState<NaturalPersonDTO & { isDirty: boolean }>()
  const [displayAddress, setDisplayedAddress] = useState<AddressCreate>(getDefaultAddressData())
  const [nifContry, setNifCountry] = useState<string>()
  const [isLoading, setIsloading] = useState<boolean>(false)

  const onInputChange = (person: NaturalPersonDTO) => {
    if (person) setUserForm({ ...person, isDirty: true })
  }
  const onSave = async () => {
    try {
      setIsloading(true)
      await nifValidation(nifContry as string, userForm as NaturalPersonDTO & { isDirty: boolean })
      setIsloading(false)
    } catch (error) {
      console.log(error)
      setIsloading(false)
    }
  }
  useEffect(() => {
    if (user) {
      setUserForm({ ...convert_naturalPerson_DTO(user.naturalPerson), isDirty: false })
      setDisplayedAddress({
        address: user.naturalPerson.Address[0].Address1,
        zip: user.naturalPerson.Address[0].ZipCode,
        country: parseInt(user.naturalPerson.Address[0].Country),
        city: user.naturalPerson.Address[0].City,
        tag: user.naturalPerson.Address[0].Tag || ''
      })
    } else setStep(stepSettings.next)
  }, [user])
  useEffect(() => {
    if (displayAddress && countries) {
      const codeCountry = countries.countries.filter(
        (country: FullCountry) => country.id === displayAddress.country
      )
      if (codeCountry && codeCountry.length > 0) setNifCountry(codeCountry[0].alpha2)
    }
  }, [displayAddress])

  return (
    <>
      {
        <>
          <SectionTitleSmall className={'mb-2'}>
            {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.nif.title')}{' '}
          </SectionTitleSmall>
          {displayAddress && countries ? (
            <div className="flex justify-between mt-4">
              <FormAddAddress
                fields={{
                  country: { visible: true, label: undefined },
                  city: { visible: false },
                  address: { visible: false },
                  zip: { visible: false },
                  tag: { visible: false }
                }}
                autoFocus
                className="m-0 py-0 flex-auto w-80"
                address={displayAddress}
                onChange={(address) => setDisplayedAddress(address)}
                quiz
              />

              <div className="flex flex-col  ml-1 ">
                <InputLabel
                  className={` flex-auto w-60`}
                  value={userForm?.NIF ?? undefined}
                  label={t('olkypass.accountPage.basicInfo.input.nif')}
                  onChange={(NIF) => onInputChange({ ...(userForm as NaturalPersonDTO), NIF })}
                  required
                ></InputLabel>
              </div>
            </div>
          ) : (
            <Loader />
          )}
          <Alert
            type={AlertType.Info}
            title={t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.nif.subtitle')}
            className="mt-4"
          >
            <p className="text-sm text-slate-200">
              {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.nif.description')}
            </p>
          </Alert>
          <div className="flex flex-col items-center mt-20 mb-4">
            <DotsNavigation total={8} current={stepSettings.current} className="mb-4" />
            <div className="w-full flex flex-col-reverse gap-y-2 md:flex-row md:gap-x-2">
              <Button theme={Themes.white} onClick={() => setStep(stepSettings.previous)}>
                {t('olkypass.general.go_back')}
              </Button>

              <Button onClick={() => onSave()} disabled={!userForm?.NIF} loading={isLoading}>
                {t('olkypass.general.continue')}
              </Button>
            </div>
          </div>
        </>
      }{' '}
    </>
  )
}
