import { KycoinIcon } from '@/atoms-react/crypto-icon/KycoinIcon.react'
import { KypayIcon } from '@/atoms-react/crypto-icon/KypayIcon.react'
import { Info } from '@/atoms-react/text/Text.react'
import { AccountSelector } from '@/components/account-selector/index.react'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { Routes } from '@/features/router'
import {
  BuildingLibraryIcon,
  ChartBarIcon,
  ChevronRightIcon,
  IdentificationIcon,
  QuestionMarkCircleIcon,
  CheckBadgeIcon,
  ExclamationCircleIcon,
  ArrowLeftStartOnRectangleIcon
} from '@heroicons/react/20/solid'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { MenuItem } from './index.react'
import { WalletIcon } from '@heroicons/react/24/solid'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { KYCStatusType } from '@/features/kyc/types'
import { getWebBankingUrl } from '@/features/utils/web-banking-url'

type MenuProps = {
  open: boolean
  smallVersion?: boolean
  onOlkyPassClick?: () => void
  onOlkyWalletClick?: () => void
} & React.ComponentPropsWithoutRef<'div'>

export const Menu = ({
  open,
  smallVersion = false,
  onOlkyPassClick = () => {},
  onOlkyWalletClick = () => {},
  ...props
}: MenuProps) => {
  const { t } = useTranslation(['pass'])
  const { logout, user, cached } = useAuth()
  const { change, id, company } = useCompanyContext()
  const { status } = useKYCContext()

  const isOlkyPayAccountVisible =
    !!user.data?.naturalPerson.olkyAccount?.iban &&
    (status === KYCStatusType.AUTHENTICATED || status === KYCStatusType.VERIFIED)

  const companyName = company?.legal_name || ''

  const redirectToBankAccount = () => {
    const url = getWebBankingUrl(cached.data?.email)
    const success = window.open(url, '_blank')
    if (!success) document.location = url
  }

  return (
    <div
      className={twMerge(
        'absolute bg-white dark:bg-slate-950 lg:relative border-r border-slate-50 dark:border-slate-900 h-full shrink-0 py-6 z-0 overflow-hidden overflow-y-auto',
        '-translate-x-full lg:translate-x-0 transition-width',
        'z-20 px-4 lg:px-8',
        open ? 'translate-x-0' : '',
        smallVersion ? 'w-16 lg:w-24 px-auto' : 'w-64 lg:w-80',
        company && 'dark bg-slate-950 border-slate-900'
      )}
      {..._.omit(props, 'className')}
    >
      <AccountSelector smallVersion={smallVersion} />

      <Info className="block mt-10"></Info>
      <MenuItem
        to={Routes.dashboard}
        smallVersion={smallVersion}
        icon={(p) => <ChartBarIcon {...p} />}
      >
        {t('olkypass.mainPage.navigation_menu.link.home')}
      </MenuItem>

      {!!id && (
        <>
          <Info className={twMerge('block mt-5 text-nowrap')}>
            <span
              className={twMerge(
                'inline-block transition-transform truncate ',
                smallVersion ? 'w-16 lg:w-20 px-auto' : 'w-64 lg:w-80',
                smallVersion && 'translate-x-[calc(-50%+1rem)]'
              )}
              title={t('olkypass.mainPage.navigation_menu.link.company_olkypass', {
                company: companyName
              })}
            >
              {t('olkypass.mainPage.navigation_menu.link.company_olkypass', {
                company: companyName
              })}
            </span>
          </Info>
          <MenuItem
            smallVersion={smallVersion}
            to={Routes.account_identity}
            icon={(p) => <IdentificationIcon {...p} />}
          >
            {t('olkypass.mainPage.navigation_menu.link.company_olkypass', { company: companyName })}
          </MenuItem>
          <MenuItem
            to={Routes.companies}
            smallVersion={smallVersion}
            icon={(p) => <BuildingLibraryIcon {...p} />}
          >
            {t('olkypass.mainPage.navigation_menu.link.company')}
          </MenuItem>
          <MenuItem
            to={Routes.support}
            smallVersion={smallVersion}
            icon={(p) => <QuestionMarkCircleIcon {...p} />}
          >
            {t('olkypass.mainPage.navigation_account_menu.link.support')}
          </MenuItem>
        </>
      )}

      {!id && (
        <>
          <Info className={twMerge('block mt-5')}>
            <span
              className={twMerge(
                'inline-block transition-transform',
                smallVersion && 'translate-x-[calc(-50%+1rem)]'
              )}
            >
              {t('olkypass.mainPage.navigation_menu.link.my_account')}
            </span>
          </Info>
          <MenuItem
            to={Routes.account_base}
            onClick={onOlkyPassClick}
            smallVersion={smallVersion}
            icon={(p) => <IdentificationIcon {...p} />}
          >
            <div className="flex justify-between items-center cursor-pointer">
              <span className="block">
                {t('olkypass.mainPage.navigation_menu.link.my_pass')}
                {status === KYCStatusType.DECLARATIVE && (
                  <ExclamationCircleIcon
                    data-position={'right'}
                    data-tooltip={t('olkypass.level.declarative_label')}
                    className="ml-2 text-red-500 inline h-5 w-auto"
                  />
                )}
              </span>{' '}
              <ChevronRightIcon
                className={twMerge('h-6 w-6 -mr-2', smallVersion ? 'hidden' : '')}
              />
            </div>
          </MenuItem>

          <MenuItem
            to={Routes.companies}
            smallVersion={smallVersion}
            icon={(p) => <BuildingLibraryIcon {...p} />}
          >
            {t('olkypass.mainPage.navigation_menu.link.company')}
          </MenuItem>

          {false && (
            <div className="flex justify-between items-center">
              <MenuItem
                to={Routes.account_identity + '/old'}
                smallVersion={smallVersion}
                icon={(p) => <IdentificationIcon {...p} />}
              >
                {t('olkypass.mainPage.navigation_menu.link.my_account')}
              </MenuItem>
              <ChevronRightIcon className="h-5 w-5 -mr-2" />
            </div>
          )}
        </>
      )}

      <Info className={twMerge('block mt-5 text-nowrap')}>
        <span
          className={twMerge(
            'inline-block transition-transform',
            smallVersion && 'translate-x-[calc(-50%+1rem)]'
          )}
        >
          {t('olkypass.mainPage.navigation_menu.link.olkyPay')}
        </span>
      </Info>
      <MenuItem
        onClick={redirectToBankAccount}
        icon={(p) => <WalletIcon {...p} />}
        isVisible={isOlkyPayAccountVisible}
      >
        <span className="flex items-center gap-x-2">
          {t('olkypass.mainPage.navigation_menu.link.olkyPayAccount_conso')}
          <ArrowTopRightOnSquareIcon className="h-4 w-4 text-inherit" />
        </span>
      </MenuItem>
      <MenuItem to={Routes.kypay} smallVersion={smallVersion} icon={(p) => <KypayIcon {...p} />}>
        {t('olkypass.mainPage.navigation_menu.link.olkyKypay')}
      </MenuItem>
      <Info className={twMerge('block mt-5 text-nowrap')}>
        <span
          className={twMerge(
            'inline-block transition-transform',
            smallVersion && 'translate-x-[calc(-50%+1rem)]'
          )}
        >
          {t('olkypass.mainPage.navigation_menu.link.olkyWallet')}
        </span>
      </Info>
      {/* <MenuItem
      to={Routes.wallet_base}
      onClick={onOlkyWalletClick}
      smallVersion={smallVersion}
      icon={(p) => <KycoinIcon className="w-5 h-5" />}
    >
      <div className="flex justify-between items-center cursor-pointer">
        <span className="block">
          {t('olkypass.mainPage.navigation_menu.link.olkyWalletDashboard')}
        </span>{' '}
        <ChevronRightIcon
          className={twMerge('h-6 w-6 -mr-2', smallVersion ? 'hidden' : '')}
        />
      </div>
    </MenuItem> */}
      <MenuItem
        to={Routes.kycoin}
        smallVersion={smallVersion}
        icon={() => <KycoinIcon className="w-5 h-5" />}
      >
        {t('olkypass.mainPage.navigation_menu.link.olkyKycoin')}
      </MenuItem>
      {!id && (
        <>
          <Info className={twMerge('block mt-5')}>
            <span
              className={twMerge(
                'inline-block transition-transform',
                smallVersion && 'translate-x-[calc(-50%+1rem)]'
              )}
            >
              {t('olkypass.mainPage.navigation_menu.link.account')}
            </span>
          </Info>
          <MenuItem
            to={Routes.support}
            smallVersion={smallVersion}
            icon={(p) => <QuestionMarkCircleIcon {...p} />}
          >
            {t('olkypass.mainPage.navigation_account_menu.link.support')}
          </MenuItem>

          <MenuItem
            to={Routes.security}
            smallVersion={smallVersion}
            icon={(p) => <CheckBadgeIcon {...p} />}
          >
            {t('olkypass.mainPage.navigation_account_menu.link.security')}
          </MenuItem>
          <MenuItem
            onClick={() => logout()}
            smallVersion={smallVersion}
            icon={(p) => <ArrowLeftStartOnRectangleIcon {...p} />}
          >
            {t('olkypass.mainPage.navigation_menu.link.logout')}
          </MenuItem>
        </>
      )}

      {!!id && (
        <>
          <Info className="block mt-8"></Info>
          <MenuItem
            onClick={() => change(null)}
            smallVersion={smallVersion}
            icon={(p) => <ArrowLeftStartOnRectangleIcon {...p} />}
          >
            {t('olkypass.mainPage.navigation_menu.link.back_to_np', { name: companyName })}
          </MenuItem>
        </>
      )}
    </div>
  )
}
