import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { useKycoin } from '@/features/kycoin/hooks/use-kycoin.ts'
import { formatNumber } from '@/features/utils/functions'
import { CoinLayout } from '@/molecules/coin-layout/index.react'
import { KYCAlert } from '@/molecules/kyc-alert/index.react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../_layout/index.react'
import { BalanceCardKycoin } from '../kypay/components/balance-card/balance-card-kycoin.react'
import { TransactionsList } from '../kypay/components/transaction-list/transaction-list.react'
import { useLocale } from '@/features/utils/hooks/use-locale.ts'
import { AccountSelector } from '@/app/olkypass/views/kypay/components/account-selector/index.react.tsx'
import { Base, Info, SectionTitle } from '@/atoms-react/text/Text.react.tsx'
import { twMerge } from 'tailwind-merge'
import { A } from '@/atoms-react/a/A.react.tsx'
import { KycoinAccount } from '@/features/kycoin/types.ts'
import { EmptyPageCactusAnimation } from '@/components/animations/empty-cactus/index.react.tsx'

export const KycoinApp = () => {
  const defaultPartnerLogoUrl = '/images/olkypass/DEFAULT-PARTNER.png'
  const { t } = useTranslation(['wallet'])
  const [search, setSearch] = useState<string>('')
  const { kycSessionStatus, status } = useKYCContext()
  const [currentAccount, setCurrentAccount] = useState<KycoinAccount>({
    is_principal_account: true
  })
  const { getKycoin, kycoinAccountsQuery } = useKycoin()
  const { data: accounts } = kycoinAccountsQuery()
  const {
    data: kycoin,
    isFetching: isLoadingKycoin,
    refetch: refetchKycoin
  } = getKycoin(currentAccount?.account_id)

  const { locale } = useLocale()
  const isFrench = (locale.language ?? '').toLocaleUpperCase() === 'FR'
  const {
    user: { isLoading: isUserLoading }
  } = useAuth()

  const allowedToKycoin =
    kycSessionStatus?.level === 100 &&
    kycSessionStatus?.ValidForPayout &&
    kycSessionStatus?.ValidForPayIn

  const transactions = kycoin?.transaction.filter((trans) =>
    (
      (isFrench ? trans.Description : trans.EnglishDescription) +
      ' ' +
      trans.formatedAmount
    ).includes(search)
  )
  const [partnerLogoUrl, setPartnerLogoUrl] = useState<string>(defaultPartnerLogoUrl)
  const fileExists = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl, { redirect: 'manual' })
      return response.ok
    } catch (error) {
      return false
    }
  }
  const changeLogo = async (account?: KycoinAccount) => {
    if (account?.is_principal_account) return null
    const partnerLogoUrlDynamic = '/images/olkypass/partners/' + account?.IDPartenaire + '/logo.png'
    // check file exist
    const exists = await fileExists(partnerLogoUrlDynamic)
    if (exists) {
      setPartnerLogoUrl(partnerLogoUrlDynamic)
    } else {
      setPartnerLogoUrl(defaultPartnerLogoUrl)
    }
  }
  const handleOnSwitchAccount = async (key) => {
    const found = (accounts ?? []).find((account) => account.account_id === key)
    setCurrentAccount(found)
    await changeLogo(found)
  }
  useEffect(() => {
    (async () => {
      await changeLogo(currentAccount)
      await refetchKycoin()
    })()
  }, [currentAccount])
  return (
    <>
      <CoinLayout
        search={search}
        setSearch={setSearch}
        title={() => (
          <>
            <LayoutTitle>{t('kycoin.label.main_title')}</LayoutTitle>
          </>
        )}
        sectionTitle={t('kycoin.label.section_title')}
        header={() => !allowedToKycoin && !isUserLoading && <KYCAlert status={status} />}
        sub_header={() => (
          <>
            <div className={'mb-4'}>
              <AccountSelector
                accounts={accounts}
                size={'lg'}
                onSwitchAccount={handleOnSwitchAccount}
                currentAccount={currentAccount}
                prefix="KYCOIN"
              ></AccountSelector>
              {!currentAccount?.is_principal_account && (
                <>
                  <SectionTitle className="overflow-hidden whitespace-nowrap text-ellipsis mt-4">
                    KYCOIN - {currentAccount.partner_name}
                  </SectionTitle>
                  <div
                    className={twMerge(
                      'min-h-48 mt-4 rounded-sm w-full h-full text-center md:text-left bg-cover bg-center'
                    )}
                    style={{
                      backgroundImage: 'url(' + partnerLogoUrl + ')'
                    }}
                  >
                    <div
                      className={twMerge(
                        'rounded-sm w-full h-full min-h-48',
                        'bg-gradient-to-l from-blue-700/30 to-blue-700-0 flex flex-col justify-center content-center flex-wrap gap-2'
                      )}
                    >
                      <Base className="text-white/90 font-bold text-lg w-100">
                        {currentAccount.partner_name}
                      </Base>
                      <A
                        href={currentAccount?.partner_website ?? '#'}
                        target={currentAccount?.partner_website ? '_blank' : '_self'}
                        noColor={true}
                        className={
                          'underline text-md font-semibold cursor-pointer text-white/90 dark:text-white w-100'
                        }
                      >
                        {t('kypay.account_switcher.lear_more')}
                      </A>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        body={() => (
          <>
            <BalanceCardKycoin
              amount={formatNumber(kycoin?.balance ?? 0, 0)}
              amountInEuro={formatNumber(kycoin?.balanceEuro ?? 0)}
              balanceType={'onChain'}
              withButton={true}
              loading={isLoadingKycoin}
              disabled={!allowedToKycoin}
              className="max-w-lg"
            />
            <div />
          </>
        )}
        transactions={{
          title: t('kycoin.section_title_transactions'),
          body: () => (
            <>
              {isLoadingKycoin && (
                <div className="w-full flex items-center flex-col space-y-4 my-16">
                  <EmptyPageCactusAnimation />
                  <Info>{t('kypay.info.no_transaction')}</Info>
                </div>
              )}

              {!isLoadingKycoin && (
                <TransactionsList
                  listType="kycoin"
                  transactions={
                    transactions?.map((trans) => ({
                      title: isFrench ? trans.Description : trans.EnglishDescription,
                      amount: trans.NumberInt,
                      type: trans.Type,
                      date: trans.Date
                    })) ?? []
                  }
                  search={search}
                  fracDigits={0}
                />
              )}
            </>
          )
        }}
      />
    </>
  )
}
