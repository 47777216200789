import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import DeclarativeWelcome from './welcome/DeclarativeWelcome.react'
import { useEffect, useState } from 'react'
import DeclarativeIdentityPerson, {
  isDeclarativePersonValid
} from './person/DeclarativeIdentityPerson.react'
import { DeclarativeIdentity } from '@/features/flow/types'
import DeclarativeIdentityBirth, {
  isDeclarativeBirthValid
} from './birth/DeclarativeIdentityBirth.react'
import DeclarativeIdentityResidence, {
  isDeclarativeResidenceValid
} from './residence/DeclarativeIdentityResidence.react'
import DeclarativeIdentityOccupation, {
  isDeclarativeOccupationValid
} from './occupation/DeclarativeIdentityOccupation.react'
import { useNavigate, useParams } from 'react-router-dom'
import { getRoute } from '@/app/olkypass/utils/routes'
import { Routes } from '@/features/router'
import { animatedNext } from '../../../layout/AnimatedForm.react'
import DeclarativeIdentityMonthlyIncome, {
  isDeclarativeMonthlyIncomeValid
} from './monthly-income/DeclarativeIdentityMonthlyIncome.react.tsx'

interface DeclarativeIdentityRootProps extends LayoutBaseProps {
  input: DeclarativeIdentityInput
  onValidated?: (data: DeclarativeIdentityOutput) => void
}

export type DeclarativeIdentityInput = {
  email: string
  phone: string
}
export type DeclarativeIdentityOutput = DeclarativeIdentity

export type DeclarativeIdentityUIState = {
  sawWelcomeMessage: boolean
}

function DeclarativeIdentityRoot({ onValidated = () => {} }: DeclarativeIdentityRootProps) {
  const { block, session_uuid, step, substep } = useParams()
  const navigate = useNavigate()

  const [declarativeIdentity, setDeclarativeIdentity] = useState<DeclarativeIdentity>()
  useEffect(() => {
    // Redirect to welcome if invalid block or just reloaded the page now
    if (!session_uuid || !substep || !declarativeIdentity?.welcome) {
      setDeclarativeIdentity(identity => ({ ...identity, welcome: true }))
      navigate(getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'person' }), {
        replace: true
      })
    }
  }, [])

  const isDeclarativeIdentityCompleted =
    isDeclarativeResidenceValid(declarativeIdentity?.addressResidence) &&
    isDeclarativeBirthValid(declarativeIdentity?.addressBirth) &&
    isDeclarativePersonValid(declarativeIdentity?.person) &&
    isDeclarativeOccupationValid(declarativeIdentity?.occupation) &&
    isDeclarativeMonthlyIncomeValid(declarativeIdentity?.monthlyIncome)
  useEffect(() => {
    if (isDeclarativeIdentityCompleted && declarativeIdentity) {
      onValidated(declarativeIdentity)
    }
  }, [isDeclarativeIdentityCompleted])
  return <>
    {substep === 'welcome' && <DeclarativeWelcome
        onValidated={() =>
          animatedNext('forward', async () => {
            setDeclarativeIdentity((identity) => ({ ...identity, welcome: true }))
            navigate(
              getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'person' })
            )
          })
        }
      />}
    {substep === 'person' && <DeclarativeIdentityPerson
        onValidated={(data) =>
          animatedNext('forward', async () => {
            setDeclarativeIdentity((identity) => ({ ...identity, person: data }))
            navigate(
              getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'birth' })
            )
          })
        }
      />}
    {substep === 'birth' && <DeclarativeIdentityBirth
        personNationality={declarativeIdentity?.person?.nationalityCodes as string[]}
        onValidated={(data) =>
          animatedNext('forward', async () => {
            setDeclarativeIdentity((identity) => ({ ...identity, addressBirth: data }))
            navigate(
              getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'residence' })
            )
          })
        }
      />}
    {substep === 'residence' && <DeclarativeIdentityResidence
        onValidated={(data) =>
          animatedNext('forward', async () => {
            setDeclarativeIdentity((identity) => ({ ...identity, addressResidence: data }))
            navigate(
              getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'activity' })
            )
          })
        }
      />}
    {substep === 'activity' && <DeclarativeIdentityOccupation
        onValidated={(data) =>
          animatedNext('forward', async () => {
            setDeclarativeIdentity((identity) => ({ ...identity, occupation: data }))
            navigate(
              getRoute(Routes.flow_substep, {
                session_uuid,
                block,
                step,
                substep: 'monthly_income'
              })
            )
          })
        }
      />}

    {substep === 'monthly_income' && <DeclarativeIdentityMonthlyIncome
        onValidated={(data) =>
          animatedNext('forward', async () => {
            setDeclarativeIdentity((identity) => ({ ...identity, monthlyIncome: data }))
            navigate(
              getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'complete' })
            )
          })
        }
      />}
  </>
}

export default DeclarativeIdentityRoot
