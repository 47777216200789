import { Button } from '@/atoms-react/button/Button.react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FormAddIban } from '../form-add-iban/index.react'

type ModalAddIbanProps = {
  iban?: string
  onValidated?: (iban: string) => void
} & ModalProps

export const ModalAddIban = ({
  iban = '',
  onValidated = () => {},
  ...props
}: ModalAddIbanProps) => {
  const { t } = useTranslation(['atoms'], { useSuspense: false })

  const [newIban, setNewIban] = useState<string>(iban)

  const [ibanValid, setIbanValid] = useState(false)

  return <Modal {...props} maxWidthClassName="max-w-2xl">
    <ModalContent
      title={
        iban ? t('molecules.modal_add_iban.title_modify') : t('molecules.modal_add_iban.title')
      }
    >
      <div className={'mt-4 opacity-1 items-end flex flex-col'}>
        <div className={'w-full mt-4 mb-6'}>
          <FormAddIban
            autoFocus
            onChange={(iban, valid) => {
              setNewIban(iban)
              setIbanValid(valid)
            }}
          />
        </div>

        <Button
          disabled={!ibanValid}
          shortcut="enter"
          className="shrink-0 w-max"
          onClick={() => onValidated(newIban)}
          icon={ChevronRightIcon}
        >
          {t('molecules.modal_add_address.confirm')}
        </Button>
      </div>
    </ModalContent>
  </Modal>
}
