import { SignupState } from '@/app/olkypass/flow/components/gateways/signup/index.react'
import _ from 'lodash'
import { NaturalPerson, StaticData } from './types'
import { Gender } from '../auth/types'

export const factory_naturalPerson_edit = (
  naturalPerson: NaturalPerson,
  signupState: SignupState,
  staticData: StaticData
): NaturalPerson => ({
  ...naturalPerson,
  Gender: (signupState.identity?.person?.gender as Gender) || null,
  DateOfBirth: {
    date: new Intl.DateTimeFormat('en-US').format(
      signupState.identity?.addressBirth?.birth ?? new Date()
    ),
    timezone_type: 3,
    timezone: 'UTC'
  },
  NIF: '',
  CountryOfCitizenship:
    staticData.countries.find(
      (country) =>
        country.alpha2.toLowerCase() ===
        (_.first(signupState.identity?.person?.nationalityCodes) ?? '').toLowerCase()
    )?.id ?? -1,
  countryOfBirth: parseInt(signupState.identity?.addressBirth?.countryCode ?? '-1'),
  Birthplace: signupState.identity?.addressBirth?.countryCode ?? '-1',
  StateOfBirth: signupState.identity?.addressBirth?.cityOfBirth ?? '',
  Address: [],
  AliasMobile: [],
  ActivityCode: parseInt(signupState?.identity?.occupation?.activity ?? '') ?? -1,
  CSPCode: signupState?.identity?.occupation?.CSPcode ?? -1,
  maximumMonthlyIncome: signupState?.identity?.monthlyIncome?.maximum,
  minimumMonthlyIncome: signupState?.identity?.monthlyIncome?.minimum
})
