import { A } from '@/atoms-react/a/A.react'
import { Button } from '@/atoms-react/button/Button.react'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react'
import Image from '@/atoms-react/image/Image.react'
import { Modal } from '@/atoms-react/modal/index.react'
import { RadioCard } from '@/atoms-react/radio-card/index.react'
import { Info, Base, CardTitle, SectionTitleSmall } from '@/atoms-react/text/Text.react'
import Flag from '@/atoms/flag/Flag.react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { KYCStatusType } from '@/features/kyc/types'
import { useBankAccount } from '@/features/user/bank-account/hooks/use-bank-account'
import { useUser } from '@/features/user/hooks/use-user'
import { getWebBankingUrl } from '@/features/utils/web-banking-url'
import { Themes } from '@/types/theme'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import confetti from 'canvas-confetti'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageSelector } from '@/components/lang-select/index.react.tsx'
import { HeaderIbanModal } from '@/molecules/modal-iban-account/header.react.tsx'
import { SourceIncomeStep } from '@/molecules/modal-iban-account/quiz/source-income-step.react.tsx'
import { MonthlyIncomeStep } from '@/molecules/modal-iban-account/quiz/monthly-income-step.react.tsx'
import { AccountReasonStep } from '@/molecules/modal-iban-account/quiz/account-reason-step.react.tsx'
import { PaymentUsageStep } from '@/molecules/modal-iban-account/quiz/payment-usage-step.react.tsx'
import { EuropeanAccountStep } from '@/molecules/modal-iban-account/quiz/european-account-step.react.tsx'
import { VideoVerificationStep } from '@/molecules/modal-iban-account/quiz/video-verification-step.react.tsx'
import { useQuiz } from '@/features/user/quiz/hooks/use-quiz.ts'
import toast from 'react-hot-toast'
import { NifVerificationStep } from './quiz/nif-verification-step.react'
import { useNifValidation } from '@/features/user/nif/hooks/use-nif-validation'
import { NaturalPersonDTO, UserType } from '@/features/user/types'
import { useLocale } from '@/features/utils/hooks/use-locale'

enum ModalIbanAccountStep {
  Start,
  SourcesIncome,
  MonthlyIncome,
  AccountReasons,
  PaymentUsage,
  NifVerification,
  EuropeanAccount,
  VideoVerification,
  Country,
  Loading,
  Success,
  Error,
  ValidatingIban
}

type ModalIbanAccountProps = {
  isOpen: boolean
  onClose: () => void
  openKYCModal: () => void
} & React.ComponentPropsWithoutRef<'div'>

export const ModalIbanAccount = ({ isOpen, onClose, openKYCModal }: ModalIbanAccountProps) => {
  const { t } = useTranslation(['pass'])
  const { status } = useKYCContext()
  const [step, setStep] = useState<ModalIbanAccountStep>(ModalIbanAccountStep.Start)
  const { useUserProfile, useEditProfile } = useUser()
  const { data: user, refetch: refetchUser } = useUserProfile()
  const { mutateAsync: editProfile } = useEditProfile()
  const { getNifValidation } = useNifValidation()
  const { mutateAsync: nififValidation } = getNifValidation()

  const { id } = useCompanyContext()
  const [country, setCountry] = useState('')
  const [iban, setIban] = useState<string>()
  const [bic, setBic] = useState<string>()

  const [progress, setProgress] = useState(0)
  const { useCountries } = useUser()
  const { locale } = useLocale()
  const { data: countries } = useCountries(locale.language)

  const [attempts, setAttempts] = useState(0)
  const maxAttempts = 5

  const { useIsValidatedIbanQuizMutation } = useQuiz()
  const { mutateAsync: isValidatedIban } = useIsValidatedIbanQuizMutation()

  const stepsSettings = {
    SourcesIncome: {
      current: 1,
      previous: ModalIbanAccountStep.Start,
      next:
        !user?.naturalPerson?.minimumMonthlyIncome && !user?.naturalPerson?.maximumMonthlyIncome
          ? ModalIbanAccountStep.MonthlyIncome
          : ModalIbanAccountStep.AccountReasons
    },

    MonthlyIncome: {
      current: 2,
      previous: ModalIbanAccountStep.SourcesIncome,
      next: ModalIbanAccountStep.AccountReasons
    },
    AccountReasons: {
      current: 3,
      previous:
        !user?.naturalPerson?.minimumMonthlyIncome && !user?.naturalPerson?.maximumMonthlyIncome
          ? ModalIbanAccountStep.MonthlyIncome
          : ModalIbanAccountStep.SourcesIncome,
      next: ModalIbanAccountStep.PaymentUsage
    },
    PaymentUsage: {
      current: 4,
      previous: ModalIbanAccountStep.AccountReasons,
      next:
        user?.naturalPerson.NIF
          ? ModalIbanAccountStep.EuropeanAccount
          : ModalIbanAccountStep.NifVerification
    },
    NifVerification: {
      current: 5,
      previous: ModalIbanAccountStep.PaymentUsage,
      next: ModalIbanAccountStep.EuropeanAccount
    },
    EuropeanAccount: {
      current: user?.naturalPerson.NIF ? 5 : 6,
      previous:
        user?.naturalPerson.NIF
          ? ModalIbanAccountStep.PaymentUsage
          : ModalIbanAccountStep.NifVerification,
      next: ModalIbanAccountStep.Country,
      condition_next: ModalIbanAccountStep.VideoVerification
    },

    VideoVerification: {
      current: user?.naturalPerson.NIF? 6 : 7,
      previous: ModalIbanAccountStep.EuropeanAccount,
      next: ModalIbanAccountStep.Country
    },
    Country: {
      current: user?.naturalPerson.NIF ? 7 : 8,
      previous: ModalIbanAccountStep.EuropeanAccount,
      next: ModalIbanAccountStep.Loading,
      condition_previous: ModalIbanAccountStep.VideoVerification
    }
  }
  const saveOnCache = () => {
    const data = {
      quizResponses: quizResponses,
      quizIbanStep: step
    }
    sessionStorage.setItem('DocumentKsyc', JSON.stringify(data))
  }
  const [quizResponses, setQuizResponses] = useState({
    sources_income: [],
    monthly_income: {},
    account_reasons: [],
    payments_usage: [],
    european_account: {
      trans_key: null
    }
  })
  useEffect(() => {
    if (isOpen) {
      if (sessionStorage.getItem('DocumentKsyc') && step === ModalIbanAccountStep.Start) return
      saveOnCache()
    }
  }, [quizResponses, step])

  useEffect(() => {
    if (sessionStorage.getItem('DocumentKsyc')) {
      const data = JSON.parse(sessionStorage.getItem('DocumentKsyc') as string)
      setStep(data.quizIbanStep)
      setQuizResponses(data.quizResponses)
    }
  }, [])
  useEffect(() => {
    let timer: NodeJS.Timeout
    if (step === ModalIbanAccountStep.Loading) {
      setProgress(0)
      const interval = 100
      const totalDuration = 3000
      let elapsed = 0

      timer = setInterval(() => {
        elapsed += interval
        setProgress((elapsed / totalDuration) * 100)
        if (elapsed >= totalDuration) {
          const onLoadingEnd = async () => {
            clearInterval(timer)
            try {
              await onCreateBankAccount()
              confettiExplosion()
              setStep(ModalIbanAccountStep.Success)
            } catch (e) {
              console.log(e)
              setStep(ModalIbanAccountStep.Error)
            }
          }
          onLoadingEnd()
        }
      }, interval)
    }
    if (step === ModalIbanAccountStep.ValidatingIban) {
      setProgress(0)
      const interval = 100
      const totalDuration = 3000
      let elapsed = 0

      timer = setInterval(() => {
        elapsed += interval
        setProgress((elapsed / totalDuration) * 100)
        if (elapsed >= totalDuration) {
          const onLoadingEnd = async () => {
            clearInterval(timer)
            try {
              const response = await isValidatedIban(quizResponses?.european_account?.iban)
              if (response.valid === false) {
                toast.error(
                  t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.european_account.error')
                )
                setStep(ModalIbanAccountStep.EuropeanAccount)
              } else {
                setStep(ModalIbanAccountStep.Country)
              }
            } catch (e) {
              console.log(e)
              toast.error(
                t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.european_account.error')
              )
              if (attempts < maxAttempts - 1) {
                // If attempts are less than maxAttempts, reset elapsed time and increment attempts
                setAttempts((prev) => prev + 1)
                elapsed = 0 // Reset elapsed time
                setProgress(0) // Reset progress
              } else {
                // If we've hit the max attempts, change the step or handle failure
                setStep(ModalIbanAccountStep.EuropeanAccount) // or whatever appropriate step
              }
            }
          }
          onLoadingEnd()
        }
      }, interval)
    }
    if (
      step === ModalIbanAccountStep.Country &&
      quizResponses?.european_account?.trans_key !== 'yes_communicate'
    ) {
      setCountry('LU')
      setStep(ModalIbanAccountStep.Loading)
    }

    return () => clearInterval(timer)
  }, [step, setStep, attempts])

  useEffect(() => {
    if (!(sessionStorage.getItem('DocumentKsyc')) ){
      if (isOpen) {
      setStep(ModalIbanAccountStep.Start)
    } else {
      setQuizResponses({
        sources_income: [],
        monthly_income: {},
        account_reasons: [],
        payments_usage: [],
        european_account: {
          trans_key: null
        }
      })
    }}
  }, [isOpen])

  const { useCreateBankAccountMutation } = useBankAccount()
  const { useCreateQuizMutation } = useQuiz()
  const { mutateAsync: createBankAccount } = useCreateBankAccountMutation()
  const { mutateAsync: saveQuizResponses } = useCreateQuizMutation()
  const countryNifChecked = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'EL',
    'ES',
    'FI',
    'FR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK'
  ]
  const nothasIban =
    typeof user?.naturalPerson.olkyAccount?.iban !== 'string' ||
    user?.naturalPerson.olkyAccount?.iban === null

  const confettiExplosion = () => {
    const duration = 2 * 750
    const animationEnd = Date.now() + duration
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 100 }

    const randomInRange = (min: number, max: number) => Math.random() * (max - min) + min

    const interval = window.setInterval(() => {
      const timeLeft = animationEnd - Date.now()

      if (timeLeft <= 0) {
        return clearInterval(interval)
      }

      const particleCount = 250 * (timeLeft / duration)

      confetti({
        ...defaults,
        particleCount: Math.floor(particleCount),
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
      })
      confetti({
        ...defaults,
        particleCount: Math.floor(particleCount),
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
      })
    }, 150)
  }

  const onCreateBankAccount = async () => {
    if (country !== 'FR' && country !== 'LU') {
      throw new Error('Error when creating olky account')
    }
    const onSaveQuiz = await saveQuizResponses(quizResponses)
    if (!onSaveQuiz.success) {
      throw new Error('Error when saving olky quiz account')
    }
    const data = await createBankAccount(country)

    if (!data.success) {
      throw new Error('Error when creating olky account')
    }

    setIban(data.iban)
    setBic(data.bic)
    await refetchUser()
  }
  const handleNifVerification = async (codeCountry: string, naturalPerson: NaturalPersonDTO) => {
    try {
      if (countryNifChecked.includes(codeCountry.toLocaleUpperCase())) {
        const response = await nififValidation({
          nif: naturalPerson.NIF as string,
          countryCode: codeCountry
        })
        if (response.validStructure && response.validSyntax) {
          await editProfile(naturalPerson)
          setStep(stepsSettings.NifVerification.next)
          await refetchUser()
        } else {
          toast.error(t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.nif.error'))
        }
      } else {
        await editProfile(naturalPerson)
        setStep(stepsSettings.NifVerification.next)
        await refetchUser()
      }
    } catch (error) {
      toast.error(t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.nif.error'))
    }
  }

  return (
    <Modal
      open={isOpen}
      closable={step !== ModalIbanAccountStep.Loading}
      onClose={onClose}
      className="sm:w-full w-full max-w-xl min-w-xl"
    >
      <div className="flex flex-col h-auto">
        {step === ModalIbanAccountStep.Start && (
          <>
            <HeaderIbanModal id={id} status={status}></HeaderIbanModal>
            {status === KYCStatusType.DECLARATIVE && nothasIban && (
              <>
                <CardTitle className="text-center mb-4">
                  {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_declarative')}
                </CardTitle>
                <Base className="text-center text-slate-400 dark:text-slate-400">
                  {t(
                    'olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_description_declarative'
                  )}
                </Base>
                <Button onClick={openKYCModal} className="mt-auto">
                  {t('olkypass.mainPage.button.completePass')}
                </Button>
              </>
            )}
            {status === KYCStatusType.NEUTRAL && nothasIban && (
              <>
                <CardTitle className="text-center mb-4">
                  {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_neutral')}
                </CardTitle>
                <Base className="text-center text-slate-400 dark:text-slate-400">
                  {t(
                    'olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_neutral_description'
                  )}
                </Base>
                <Button onClick={onClose} className="mt-auto">
                  {t('olkypass.general.understand')}
                </Button>
              </>
            )}
            {(status === KYCStatusType.VERIFIED || status === KYCStatusType.AUTHENTICATED) &&
              nothasIban && (
                <>
                  <Base className="text-center text-slate-400 dark:text-slate-400 mb-4">
                    {t(
                      'olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.start.description.part_1'
                    )}
                    <span className="text-sky-500">
                      {' '}
                      {t(
                        'olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.start.description.part_2'
                      )}
                    </span>
                  </Base>
                  <SectionTitleSmall>
                    {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.start.question')}
                  </SectionTitleSmall>

                  <div className="flex flex-col min-w-full justify-center mb-4 mt-4">
                    <LanguageSelector size="lg" />
                  </div>
                  <div className="flex flex-col items-center mt-auto">
                    <DotsNavigation total={7} current={0} className="mb-4" />
                    <Button
                      onClick={() => {
                        setStep(ModalIbanAccountStep.SourcesIncome)
                      }}
                    >
                      {t('olkypass.general.continue')}
                    </Button>
                  </div>
                </>
              )}
          </>
        )}
        {step === ModalIbanAccountStep.SourcesIncome && (
          <>
            <HeaderIbanModal id={id} status={status}></HeaderIbanModal>
            <SourceIncomeStep
              setStep={setStep}
              ModalIbanAccountStep={ModalIbanAccountStep}
              quizResponses={quizResponses}
              setQuizResponses={setQuizResponses}
              stepSettings={stepsSettings.SourcesIncome}
              countries={countries}
            ></SourceIncomeStep>
          </>
        )}

        {step === ModalIbanAccountStep.NifVerification && (
          <>
            <HeaderIbanModal id={id} status={status}></HeaderIbanModal>
            <NifVerificationStep
              user={user as UserType}
              nifValidation={handleNifVerification}
              stepSettings={stepsSettings.NifVerification}
              setStep={setStep}
              countries={countries}
              editProfile={editProfile}
              refetchUser={refetchUser}
            />
          </>
        )}
        {step === ModalIbanAccountStep.MonthlyIncome && (
          <>
            <HeaderIbanModal id={id} status={status}></HeaderIbanModal>
            <MonthlyIncomeStep
              setStep={setStep}
              ModalIbanAccountStep={ModalIbanAccountStep}
              quizResponses={quizResponses}
              setQuizResponses={setQuizResponses}
              stepSettings={stepsSettings.MonthlyIncome}
            ></MonthlyIncomeStep>
          </>
        )}
        {step === ModalIbanAccountStep.AccountReasons && (
          <>
            <HeaderIbanModal id={id} status={status}></HeaderIbanModal>
            <AccountReasonStep
              setStep={setStep}
              ModalIbanAccountStep={ModalIbanAccountStep}
              quizResponses={quizResponses}
              setQuizResponses={setQuizResponses}
              stepSettings={stepsSettings.AccountReasons}
            ></AccountReasonStep>
          </>
        )}
        {step === ModalIbanAccountStep.PaymentUsage && (
          <>
            <HeaderIbanModal id={id} status={status}></HeaderIbanModal>
            <PaymentUsageStep
              setStep={setStep}
              ModalIbanAccountStep={ModalIbanAccountStep}
              quizResponses={quizResponses}
              setQuizResponses={setQuizResponses}
              stepSettings={stepsSettings.PaymentUsage}
            ></PaymentUsageStep>
          </>
        )}

        {step === ModalIbanAccountStep.EuropeanAccount && (
          <>
            <HeaderIbanModal id={id} status={status}></HeaderIbanModal>
            <EuropeanAccountStep
              setStep={setStep}
              ModalIbanAccountStep={ModalIbanAccountStep}
              quizResponses={quizResponses}
              setQuizResponses={setQuizResponses}
              stepSettings={stepsSettings.EuropeanAccount}
            ></EuropeanAccountStep>
          </>
        )}
        {step === ModalIbanAccountStep.ValidatingIban && (
          <div className="flex flex-col items-center justify-center h-full">
            <CardTitle className="text-center mb-4">
              {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.validating_your_iban')}
            </CardTitle>
            <div className="w-full h-2 bg-gray-200 rounded-full">
              <div
                className="h-2 bg-blue-500 rounded-full"
                style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
              />
            </div>
          </div>
        )}
        {step === ModalIbanAccountStep.VideoVerification && (
          <>
            <HeaderIbanModal id={id} status={status}></HeaderIbanModal>
            <VideoVerificationStep
              setStep={setStep}
              ModalIbanAccountStep={ModalIbanAccountStep}
              quizResponses={quizResponses}
              setQuizResponses={setQuizResponses}
              stepSettings={stepsSettings.VideoVerification}
              user={user}
            ></VideoVerificationStep>
          </>
        )}
        {step === ModalIbanAccountStep.Country && (
          <>
            <HeaderIbanModal id={id} status={status}></HeaderIbanModal>
            <>
              <Base className="mb-4">
                {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.choose_country_text')}
              </Base>

              <div className="space-y-4 my-4 w-full mt-auto">
                {['FR', 'LU'].map((c) =>
                  quizResponses?.european_account?.trans_key !== 'yes_communicate' &&
                  c === 'FR' ? null : (
                    <RadioCard active={country === c} key={c} onClick={() => setCountry(c)}>
                      <Flag countryCode={c} className="mb-4" />
                      <Base>IBAN {c}</Base>
                      <Info>{c}XX XXXX XXXX XXXX XXX</Info>
                    </RadioCard>
                  )
                )}
              </div>

              <div className="flex flex-col items-center mt-auto mb-4">
                <DotsNavigation
                  total={7}
                  current={stepsSettings.Country.current}
                  className="mb-4"
                />
                <div className="w-full flex flex-col-reverse gap-y-2 md:flex-row md:gap-x-2">
                  <Button
                    theme={Themes.white}
                    onClick={() => {
                      if (
                        quizResponses?.european_account?.trans_key === '' ||
                        quizResponses?.european_account?.trans_key === 'yes_communicate'
                      ) {
                        setStep(stepsSettings.Country.previous)
                      } else {
                        setStep(stepsSettings.Country.condition_previous)
                      }
                    }}
                  >
                    {t('olkypass.general.go_back')}
                  </Button>

                  <Button
                    disabled={!country}
                    onClick={() => {
                      setStep(stepsSettings.Country.next)
                    }}
                  >
                    {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.button_create_my_account')}
                  </Button>
                </div>
              </div>
            </>
          </>
        )}
        {step === ModalIbanAccountStep.Loading && (
          <div className="flex flex-col items-center justify-center h-full">
            <CardTitle className="text-center mb-4">
              {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.creating_your_account')}
            </CardTitle>
            <div className="w-full h-2 bg-gray-200 rounded-full">
              <div
                className="h-2 bg-blue-500 rounded-full"
                style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
              />
            </div>
          </div>
        )}
        {step === ModalIbanAccountStep.Success && (
          <>
            <div className="h-2/5 md:h-1/2 w-full rounded-sm mb-6 flex items-center justify-center overflow-hidden mt-7 sm:mt-5">
              <div className="w-full h-full rounded-sm border-2 border-slate-50 p-4 relative overflow-hidden flex flex-col">
                <div className="h-40 w-40 absolute opacity-20 right-0 bottom-0">
                  <Image src="images/olkypass/leaves.png" alt="leaves" />
                </div>

                {/* User First and Last Name */}
                <div className="flex justify-between items-center mb-6">
                  <CardTitle className="font-semibold">
                    {user?.naturalPerson.FirstName} {user?.naturalPerson.LastName}
                  </CardTitle>
                  <Image src="images/olkypass/olkyPayLogo.svg" alt="OlkyPay logo" />
                </div>

                {/* IBAN and BIC Container */}
                <div className="flex grow flex-col justify-center">
                  {/* IBAN */}
                  <div className="mb-3">
                    <Base className="text-slate-500">IBAN</Base>
                    <Base className="font-semibold">{iban?.replace(/(.{4})/g, '$1 ').trim()}</Base>
                  </div>
                  {/* BIC */}
                  <div>
                    <Base className="text-slate-500">BIC</Base>
                    <Base className="font-semibold">{bic}</Base>
                  </div>
                </div>
              </div>
            </div>
            <>
              <CardTitle className="text-center mb-4">
                {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.congratulations')}
              </CardTitle>
              <Base className="text-center text-slate-400 dark:text-slate-400 mb-4">
                {id
                  ? t('olkypass.mainPage.olkypass.modalOpenOlkyPay.congratulations_pro_description')
                  : t('olkypass.mainPage.olkypass.modalOpenOlkyPay.congratulations_description')}
              </Base>
              <div className="flex flex-col items-center mt-auto">
                {/*<DotsNavigation total={3} current={2} className="mb-4" />*/}
                <div className="w-full flex flex-col-reverse gap-y-2 md:flex-row md:gap-x-2">
                  <Button
                    theme={Themes.white}
                    onClick={() => {
                      onClose()
                      sessionStorage.removeItem('DocumentKsyc')
                    }}
                  >
                    {t('olkypass.general.close')}
                  </Button>
                  <A
                    href={getWebBankingUrl(
                      user?.naturalPerson.AliasEmail?.find((a) => a?.Actif)?.Mail
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button onClick={() => {}}>
                      {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.open_webbanking_button')}
                    </Button>
                  </A>
                </div>
              </div>
            </>
          </>
        )}
        {step === ModalIbanAccountStep.Error && (
          <>
            <div className="h-2/5 md:h-1/2 w-full rounded-sm mb-6 flex items-center justify-center overflow-hidden">
              <ExclamationTriangleIcon className="text-red-500 h-full w-auto" />
            </div>
            <>
              <CardTitle className="text-center mb-4">
                {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.error')}
              </CardTitle>
              <Base className="text-center text-slate-400 dark:text-slate-400">
                {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.error_description')}
              </Base>
              <div className="flex flex-col items-center mt-auto">
                {/*<DotsNavigation total={3} current={2} className="mb-4" />*/}
                <Button
                  onClick={() => {
                    onClose()
                    sessionStorage.removeItem('DocumentKsyc')
                  }}
                >
                  {t('olkypass.accountPage.dialog.newItems.button.finish')}
                </Button>
              </div>
            </>
          </>
        )}
      </div>
    </Modal>
  )
}
