import { fetchServer } from '@/features/utils/fetch'
import {
  CreateWalletResponse,
  FeedKypayBalanceProps,
  FeedKypayBalanceResponse,
  GetWalletPolygon2LausanneResponse,
  GetWalletResponse,
  Kypay,
  KypayAccount,
  SendKypayToUserWalletProps,
  SendKypayToUserWalletReponse,
  WithdrawKypayBalanceProps,
  WithdrawKypayBalanceResponse
} from '../types'

export class KypayApiClient {
  static async getKypay(account_id): Promise<Kypay> {
    const response = await fetchServer('/user/kypay', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: {
        account_id
      }
    })
    return await response.json() as Kypay
  }

  static async getUserWallet(): Promise<GetWalletResponse> {
    const response = await fetchServer('/kypay/web3/wallet', {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })
    const data = await response.json() as GetWalletResponse

    return data
  }

  static async getWalletPolygon2Lausanne(): Promise<GetWalletPolygon2LausanneResponse> {
    const response = await fetchServer('/kypay/web3/address/polygon2lausanne', {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })
    const data = await response.json() as GetWalletPolygon2LausanneResponse

    return data
  }

  static async createUserWallet(wallet: string): Promise<CreateWalletResponse> {
    const response = await fetchServer('/kypay/web3/wallet/create', {
      method: 'POST',
      body: {
        walletAddress: wallet
      },
      reloadOn401: false,
      mainRoot: false
    })
    const data = await response.json() as CreateWalletResponse
    return data
  }

  static async sendKypayToUserWallet(
    sendData: SendKypayToUserWalletProps
  ): Promise<SendKypayToUserWalletReponse> {
    const response = await fetchServer('/kypay/web3/transfer', {
      method: 'POST',
      body: sendData,
      reloadOn401: false,
      mainRoot: false
    })
    const data = await response.json() as SendKypayToUserWalletReponse
    return data
  }

  static async feedKypayBalance(data: FeedKypayBalanceProps): Promise<FeedKypayBalanceResponse> {
    const response = await fetchServer('/kypay/fund_account', { body: data })
    if (!response.ok) {
      throw new Error()
    }

    const res = await response.json()
    return res.data
  }

  static async withdrawKypayBalance(
    data: WithdrawKypayBalanceProps
  ): Promise<WithdrawKypayBalanceResponse> {
    const response = await fetchServer('/kypay/withdraw', { body: data })
    if (!response.ok) {
      throw new Error()
    }

    const res = await response.json()
    return res.data
  }
  static async getKypayAccounts(): Promise<KypayAccount[]> {
    const response = await fetchServer('/kypay/my_accounts', {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })
    if (!response.ok) {
      throw new Error()
    }

    return await response.json() as KypayAccount[]
  }
}
