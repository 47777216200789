'use client'
import { A } from '@/atoms-react/a/A.react'
import { Button } from '@/atoms-react/button/Button.react'
import { KypayIcon } from '@/atoms-react/crypto-icon/KypayIcon.react'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import {
  addKypayToWallet,
  selectPolygonNetwork,
  isMetamaskActive
} from '@/features/kypay/functions'
import { useKypay } from '@/features/kypay/hooks/use-kypay'
import { CoinLayout } from '@/molecules/coin-layout/index.react'
import { KYCAlert } from '@/molecules/kyc-alert/index.react'
import { Themes } from '@/types/theme'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../../../_layout/index.react'
import { BalanceCardKypay } from '../balance-card/balance-card-kypay.react'
import { TransactionsList } from '../transaction-list/transaction-list.react'
import { CardStack } from '../card-stack/card-stack.react'

import { ModalDiscoverKYPAY } from '@/app/olkypass/views/kypay/components/modal-discover-kypay/index.react.tsx'
import { useLocale } from '@/features/utils/hooks/use-locale.ts'
import { AccountSelector } from '@/app/olkypass/views/kypay/components/account-selector/index.react.tsx'
import { twMerge } from 'tailwind-merge'
import { Base, Info, SectionTitle } from '@/atoms-react/text/Text.react'
import { KypayAccount } from '@/features/kypay/types.ts'
import { EmptyPageCactusAnimation } from '@/components/animations/empty-cactus/index.react.tsx'

export const KypayRoot = () => {
  const defaultPartnerLogoUrl = '/images/olkypass/DEFAULT-PARTNER.png'
  const { kycSessionStatus, status } = useKYCContext()
  const { t } = useTranslation(['wallet'])
  const [search, setSearch] = useState<string>('')
  const [currentAccount, setCurrentAccount] = useState<KypayAccount>({ is_principal_account: true })
  const { kypayQuery, userWalletQuery, kypayAccountsQuery } = useKypay()
  const { data: accounts } = kypayAccountsQuery()
  const {
    data: kypay,
    isFetching: isKypayLoading,
    refetch: refetchKypay
  } = kypayQuery(currentAccount?.account_id)

  const { isLoading: isWalletLoading } = userWalletQuery()
  const { locale } = useLocale()
  const isFrench = (locale.language ?? '').toLocaleUpperCase() === 'FR'
  const transactions = (kypay?.transaction ?? []).filter(
    (trans) =>
      ((isFrench ? trans.Description : trans.EnglishDescription) ?? '').includes(search) ||
      trans.formatedAmount.includes(search)
  )

  const {
    user: { isLoading: isUserLoading }
  } = useAuth()

  const allowedToKypay =
    kycSessionStatus?.level === 100 &&
    kycSessionStatus?.ValidForPayout &&
    kycSessionStatus?.ValidForPayIn
  const [partnerLogoUrl, setPartnerLogoUrl] = useState<string>(defaultPartnerLogoUrl)
  const fileExists = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl, { redirect: 'manual' })
      return response.ok
    } catch (error) {
      return false
    }
  }
  const changeLogo = async (account?: KypayAccount) => {
    if (account?.is_principal_account) return null
    const partnerLogoUrlDynamic = '/images/olkypass/partners/' + account?.IDPartenaire + '/logo.png'
    // check file exist
    const exists = await fileExists(partnerLogoUrlDynamic)
    if (exists) {
      setPartnerLogoUrl(partnerLogoUrlDynamic)
    } else {
      setPartnerLogoUrl(defaultPartnerLogoUrl)
    }
  }

  const handleOnSwitchAccount = async (key) => {
    const found = (accounts ?? []).find((account) => account.account_id === key)
    setCurrentAccount(found)
    await changeLogo(found)
  }

  useEffect(() => {
    (async () => {
      await changeLogo(currentAccount)
      await refetchKypay()
    })()
  }, [currentAccount])

  // this force current account to get the info from accounts
  useEffect(() => {
    if (accounts && !currentAccount?.account_id) {
      const found = (accounts ?? []).find((account) => account.is_principal_account === true)
      setCurrentAccount(found)
    }
  }, [accounts])
  return (
    <>
      <CoinLayout
        search={search}
        setSearch={setSearch}
        title={() => (
          <>
            <LayoutTitle>{t('kypay.label.main_title')}</LayoutTitle>
            {isMetamaskActive() && (
              <Button
                size="md"
                theme={Themes.white}
                onClick={async () => {
                  await selectPolygonNetwork()
                  await addKypayToWallet()
                }}
                icon={KypayIcon}
                iconPosition="before"
                className="hidden md:block md:overflow-visible md:w-max mr-4"
              >
                {t('kypay.button.add_kypay_token')}
              </Button>
            )}
            <A noColor target="_blank" href="https://www.olky.eu/fr/universe/olkypay/kypay/">
              <Button
                size="md"
                theme={Themes.white}
                onClick={() => null}
                icon={QuestionMarkCircleIcon}
                iconPosition="before"
                className="hidden md:block md:overflow-visible md:w-max"
              >
                {t('kypay.button.need_help')}
              </Button>
            </A>
          </>
        )}
        sectionTitle={t('kypay.label.section_title')}
        header={() =>
          !allowedToKypay &&
          !isWalletLoading &&
          !isKypayLoading &&
          !isUserLoading && <KYCAlert status={status} />
        }
        sub_header={() => (
          <>
            <div className={'mb-4'}>
              <AccountSelector
                accounts={accounts}
                size={'lg'}
                onSwitchAccount={handleOnSwitchAccount}
                currentAccount={currentAccount}
                prefix="KYPAY"
              ></AccountSelector>
              {!currentAccount?.is_principal_account && (
                <>
                  <SectionTitle className="overflow-hidden whitespace-nowrap text-ellipsis mt-4">
                    KYPAY - {currentAccount.partner_name}
                  </SectionTitle>
                  <div
                    className={twMerge(
                      'min-h-48 mt-4 rounded-sm w-full h-full text-center md:text-left bg-cover bg-center'
                    )}
                    style={{
                      backgroundImage: 'url(' + partnerLogoUrl + ')'
                    }}
                  >
                    <div
                      className={twMerge(
                        'rounded-sm w-full h-full min-h-48',
                        'bg-gradient-to-l from-blue-700/30 to-blue-700-0 flex flex-col justify-center content-center flex-wrap gap-2'
                      )}
                    >
                      <Base className="text-white/90 font-bold text-lg w-100">
                        {currentAccount.partner_name}
                      </Base>
                      <A
                        href={currentAccount?.partner_website ?? '#'}
                        target={currentAccount?.partner_website ? '_blank' : '_self'}
                        noColor={true}
                        className={
                          'underline text-md font-semibold cursor-pointer text-white/90 dark:text-white w-100'
                        }
                      >
                        {t('kypay.account_switcher.lear_more')}
                      </A>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        body={() => (
          <>
            <BalanceCardKypay
              allowedToKypay={allowedToKypay}
              balanceType="euros"
              className="max-w-lg"
              withButton={true}
              isPrincipalAccount={currentAccount?.is_principal_account}
              kypay={kypay}
              refetchKypay={refetchKypay}
              isKypayLoading={isKypayLoading}
              currentAccount={currentAccount}
            />
            {currentAccount?.is_principal_account && (
              <BalanceCardKypay
                allowedToKypay={allowedToKypay}
                balanceType="onChain"
                className="max-w-lg"
                withButton={true}
                kypay={kypay}
                refetchKypay={refetchKypay}
                isKypayLoading={isKypayLoading}
                currentAccount={currentAccount}
              />
            )}
            <CardStackKypay />
          </>
        )}
        transactions={{
          title: t('kypay.section_title_transactions'),
          body: () => (
            <>
              {isKypayLoading && (
                <div className="w-full flex items-center flex-col space-y-4 my-16">
                  <EmptyPageCactusAnimation />
                  <Info>{t('kypay.info.no_transaction')}</Info>
                </div>
              )}

              {!isKypayLoading && (
                <TransactionsList
                  transactions={
                    transactions.map((trans) => ({
                      title: isFrench ? trans.Description : trans.EnglishDescription,
                      amount: trans.NumberInt / 100,
                      type: trans.Type,
                      date: trans.Date
                    })) ?? []
                  }
                  search={search}
                />
              )}
            </>
          )
        }}
      />
    </>
  )
}

export function CardStackKypay() {
  const { t } = useTranslation(['pass'])
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const onDiscoverKYPAYModalDisplay = () => setIsOpenModal(true)

  const CARDS = [
    {
      id: 0,
      title: t('olkypass.KypayPage.cardsExplanationsKypay.title.1'),
      children: (
        <>
          <div className="sticky bottom-0 left-0 right-0 flex gap-x-3 justify-center items-center z-10 mt-6 sm:mt-16">
            <A
              onClick={onDiscoverKYPAYModalDisplay}
              noColor={true}
              className={
                'underline text-md font-semibold cursor-pointer text-black dark:text-white'
              }
            >
              {t('olkypass.KypayPage.cardsExplanationsKypay.content.1.link')}
            </A>
          </div>
        </>
      )
    }
  ]

  return (
    <>
      <div className="flex items-center w-full max-w-lg">
        <CardStack items={CARDS} />
      </div>

      <ModalDiscoverKYPAY
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      ></ModalDiscoverKYPAY>
    </>
  )
}
