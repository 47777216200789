import { CheckCircle } from '@/atoms-react/check-circle/check-circle.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

export type PasswordOutput = {
  password: string
  valid: boolean
}

type AccountSecurityPasswordProps = {
  onChange: (output: PasswordOutput) => void
  label?: string
}

export const PasswordInput = ({ onChange = () => {}, label }: AccountSecurityPasswordProps) => {
  const { t } = useTranslation(['pass'])
  const [password, setPassword] = useState<string>('')

  const isOneDigit = password.match(/(?=.*\d)/)
  const isOneLowerCase = password.match(/(.*[a-z].*)/)
  const isOneUpperCase = password.match(/(.*[A-Z].*)/)
  const isAtLeastLong = password.length >= 13

  const isPasswordValid = isOneDigit && isOneLowerCase && isOneUpperCase && isAtLeastLong

  useEffect(() =>{ onChange({ password, valid: isPasswordValid || false })}, [password])

  return <>
    <InputLabel
      autoFocus
      type="password"
      placeholder={t('flow.id_control.input.placeholder_password')}
      value={password}
      onChange={(value: string) => setPassword(value)}
      label={label ? label : t('flow.id_control.input.password')}
    />
    <div className={twMerge('flex pb-3 mt-8 items-center', isOneDigit && 'text-green-500')}>
      <CheckCircle
        width={24}
        height={24}
        className={isOneDigit ? 'bg-green-500' : 'bg-slate-500'}
      />
      <span>{t('flow.id_control.signup.label.password_criteria_1')}</span>
    </div>
    <div className={twMerge('flex pb-3 items-center', isOneLowerCase && 'text-green-500')}>
      <CheckCircle
        width={24}
        height={24}
        className={isOneLowerCase ? 'bg-green-500' : 'bg-slate-500'}
      />
      <span>{t('flow.id_control.signup.label.password_criteria_2')}</span>
    </div>
    <div className={twMerge('flex pb-3 items-center', isOneUpperCase && 'text-green-500')}>
      <CheckCircle
        width={24}
        height={24}
        className={isOneUpperCase ? 'bg-green-500' : 'bg-slate-500'}
      />
      <span>{t('flow.id_control.signup.label.password_criteria_3')}</span>
    </div>
    <div className={twMerge('flex pb-3 items-center', isAtLeastLong && 'text-green-500')}>
      <CheckCircle
        width={24}
        height={24}
        className={isAtLeastLong ? 'bg-green-500' : 'bg-slate-500'}
      />
      <span>{t('flow.id_control.signup.label.password_criteria_4')}</span>
    </div>
  </>
}
