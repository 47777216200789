import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { atom, useRecoilState } from 'recoil'
import { SectionTitle } from '../text/Text.react'
import { twMerge } from 'tailwind-merge'
import { uniqueId } from 'lodash'
import _ from 'lodash'
import { KypayAccount } from '@/features/kypay/types.ts'

const ModalsCountState = atom<string[]>({
  key: 'ModalsState',
  default: []
})

const visibleModals = { stack: [] as string[] }

export type ModalProps = {
  open?: boolean
  onClose?: () => void
  children?: React.ReactNode
  closable?: boolean
  className?: string
  positionWrapperClassName?: string
  style?: any
  positioned?: boolean
  maxWidthClassName?: string
  onClickOutside?: () => void
  currentAccount?: KypayAccount
}

export const Modal = ({ maxWidthClassName, onClickOutside = () => {}, ...props }: ModalProps) => {
  const modalId = useRef(uniqueId())
  const [open, setOpen] = useState(false)
  const [modalsCountState, setModalsCountState] = useRecoilState(ModalsCountState)
  const [level, setLevel] = useState(0)
  const openStatus = useRef<boolean>(false)

  const onClose = useCallback(() => {
    openStatus.current = false
    visibleModals.stack = visibleModals.stack.filter(a => a !== modalId.current)
    setModalsCountState(visibleModals.stack)
  }, [setModalsCountState])

  const onOpen = useCallback(() => {
    openStatus.current = true
    visibleModals.stack = _.uniq([...visibleModals.stack, modalId.current])
    setLevel(visibleModals.stack.findIndex(a => a === modalId.current))
    setModalsCountState(visibleModals.stack)
  }, [setModalsCountState])

  useEffect(() => {
    if (props.open !== open) {
      setOpen(props.open || false)
      if (props.open) {
        onOpen()
      } else {
        onClose()
      }
    }
  }, [props.open, open, onClose, onOpen])

  useEffect(
    () => () => {
      if (openStatus.current) onClose()
    },
    [openStatus, onClose]
  )

  const zIndex = 'z-[' + (level + 5) + '0]'
  const active = modalsCountState[modalsCountState.length - 1] === modalId.current

  return <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className={'relative ' + zIndex} onClose={onClickOutside}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 pointer-events-none"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0 pointer-events-none"
      >
        <div
          className={'fixed inset-0 bg-opacity-25 dark:bg-opacity-75 transition-opacity bg-black'}
        />
      </Transition.Child>

      <div
        className={
          'fixed z-10 inset-0 overflow-y-auto transition-transform ' +
          (!active && open
            ? '-translate-y-6 sm:scale-95 opacity-75 '
            : !active && !open
              ? 'translate-y-6 sm:translate-y-0 sm:scale-100 opacity-75 '
              : '')
        }
      >
        <div
          className={twMerge(
            'flex items-end justify-center min-h-dvh text-center block ',
            props.positionWrapperClassName
          )}
        >
          {
            /* This element is to trick the browser into centering the modal contents. */
            !props.positioned && <span className="inline-block align-middle h-dvh" aria-hidden="true">
                &#8203;
              </span>
          }
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 pointer-events-none translate-y-4 sm:translate-y-0"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 pointer-events-none translate-y-4 sm:translate-y-0"
          >
            <Dialog.Panel
              className={twMerge(
                'relative inline-block align-middle	 sm:align-bottom bg-white dark:bg-slate-900 rounded-tr-xl rounded-tl-xl sm:rounded-xl px-4 pt-3 pb-3 text-left w-full sm:w-auto overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:w-full sm:p-6 ',
                props.className,
                maxWidthClassName
              )}
              style={props.style || {}}
            >
              {props.closable !== false && <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white dark:bg-slate-800 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                    onClick={() => props.onClose && props.onClose()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>}
              {props.children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
}

export const ModalContent = (props: {
  title: string
  text?: string
  buttons?: ReactNode
  children?: ReactNode
  icon?: any
  theme?: 'success' | 'danger' | 'warning' | 'gray'
}) => {
  let color = 'indigo'
  if (props.theme === 'success') color = 'green'
  if (props.theme === 'danger') color = 'red'
  if (props.theme === 'warning') color = 'orange'
  if (props.theme === 'gray') color = 'gray'
  return <>
    <div className="sm:flex sm:items-start">
      {props.icon && <div
          className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${color}-100 sm:mx-0 sm:h-10 sm:w-10`}
        >
          <props.icon className={`h-6 w-6 text-${color}-600`} aria-hidden="true" />
        </div>}
      <div className={'mt-3 text-center sm:mt-0 sm:text-left ' + (props.icon ? 'sm:ml-4' : '')}>
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
        >
          <SectionTitle>{props.title}</SectionTitle>
        </Dialog.Title>
        <div className="mt-2">
          <p className="text-sm text-gray-500 dark:text-white">{props.text || ''}</p>
        </div>
      </div>
    </div>
    {props.buttons && <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse text-center sm:text-left">
        {props.buttons}
      </div>}
    {props.children}
  </>
}
