import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import Tag from '@/atoms-react/tag/Tag.react'
import { Base, CardTitle } from '@/atoms-react/text/Text.react'
import { useUser } from '@/features/user/hooks/use-user'
import { AddressFullType } from '@/features/user/types'
import { useLocale } from '@/features/utils/hooks/use-locale'
import { Themes } from '@/types/theme'
import { TrashIcon } from '@heroicons/react/20/solid'
import { HomeIcon, PencilSquareIcon, ShieldCheckIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { useTranslation } from 'react-i18next'

type AddressCardProps = {
  address: AddressFullType
  onEdit?: () => void
  onDelete?: () => void
} & React.ComponentPropsWithoutRef<'div'>

export const AddressCard = ({
  address,
  onEdit = () => {},
  onDelete,
  ...props
}: AddressCardProps) => {
  const { t } = useTranslation(['pass'])
  const { locale } = useLocale()
  const { useCountries } = useUser()
  const { data: countries } = useCountries(locale?.language)

  return <Column {...props}>
    {/* Add min height to the card */}
    <div className="border-2 border-slate-50  dark:border-b-2 dark:border-slate-700 min-h-64   rounded-sm px-6 py-4">
      {/* First row of the card */}
      <div className="flex items-center mb-6">
        <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full border-2 border-blue-500 mr-3">
          <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center">
            <HomeIcon className="text-white h-6 w-6" />
          </div>
        </div>
        <div className="flex flex-wrap gap-x-3 flex-1">
          <CardTitle>{address.Tag}</CardTitle>
          {false && <Tag>{t('aliasAddressPage.tag')}</Tag>}
        </div>
        <div data-tooltip={t('olkypass.general.coming_soon')}>
          <Button onClick={() => null} size="md" icon={ShieldCheckIcon} disabled={true}>
            <span className="md:inline hidden">{t('aliasAddressPage.button.check')}</span>
          </Button>
        </div>
      </div>
      {/* Adress infos */}
      <div className="mb-6">
        <Base>{address.Address1.replace(',', '')}</Base>
        <Base>
          {address.City} {address.ZipCode}
        </Base>
        <Base>
          {
            (countries?.countries ?? []).find(c => c.id.toString() === address.Country)
              ?.defaultName
          }
        </Base>
      </div>

      {/* If adress is not checked */}
      {false && <Base className="text-sm text-slate-200">{t('aliasAddressPage.address_text')}</Base>}
      {/* If adress is checked */}
      {true && <div className="flex gap-x-2 md:mb-2 justify-end">
          {onDelete && <Button
              contentProps={{ className: 'space-x-0 md:space-x-2' }}
              onClick={onDelete}
              size="md"
              className="w-max"
              theme={Themes.danger}
              icon={TrashIcon}
            >
              <span className="md:inline hidden">{t('aliasAddressPage.button.delete')}</span>
            </Button>}
          <Button
            contentProps={{ className: 'space-x-0 md:space-x-2' }}
            onClick={onEdit}
            size="md"
            className="w-auto"
            theme={Themes.white}
            icon={PencilSquareIcon}
          >
            <span className="md:inline hidden">{t('aliasAddressPage.button.edit')}</span>
          </Button>
        </div>}
      {/* If adress beign checked */}
      {false && <Tag theme={Themes.warning}>{t('aliasAddressPage.address_checking')}</Tag>}
    </div>
  </Column>
}
