import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { SecurityApiClient } from '../api-client/api-client'
import { ValidationEmailCodeResponse, ValidationSensitiveEmailCodeParams } from '../types'

export default function useValidateSensitiveEmailMutation(
  options?: UseMutationOptions<
    ValidationEmailCodeResponse,
    Error,
    ValidationSensitiveEmailCodeParams
  >
) {
  return useMutation<ValidationEmailCodeResponse, Error, ValidationSensitiveEmailCodeParams>({
    mutationFn: async (params: ValidationSensitiveEmailCodeParams) => {
      const res = await SecurityApiClient.validateSensitiveEmail(
        params.uuidSensitiveActionValidation,
        params.codeEmail,
        params.actionType
      )
      if (!res.success) {
        throw new Error(res.data)
      }
      return res
    },
    ...options
  })
}
