import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { EmailApiClient } from '../api-client/api-client'
import { ValidateEmailMutationProps } from '../../types'
import { ConfirmationResponse } from '@/features/register/types'

export const useCreateEmailMutation = (
  options?: UseMutationOptions<ConfirmationResponse, Error, ValidateEmailMutationProps>
) => {
  const { t } = useTranslation(['pass'])

  return useMutation<ConfirmationResponse, Error, ValidateEmailMutationProps>({
    mutationFn: async ({ code, email, cypher, UUID }: ValidateEmailMutationProps) => {
      // @TODO detect language correctly
      try {
        return await EmailApiClient.createEmail(cypher, code, UUID, email)
      } catch (e: any) {
        throw new Error(t('olkypass.general.error_occured'))
      }
    },
    ...options
  })
}
