export const KypayInstrumentIcon = (props: {
  size?: number
  color?: string
  style?: any
  className?: string
}) => (
  <div className={props.className} style={props.style}>
    <img src="/images/olkypass/KypayInstrumentIcon.png" alt="KYPAY" />
  </div>
)
