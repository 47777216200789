import { getRoute } from '@/app/olkypass/utils/routes'
import { A } from '@/atoms-react/a/A.react'
import { Skeletton } from '@/atoms-react/skeletton/index.react'
import { Base } from '@/atoms-react/text/Text.react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { Routes } from '@/features/router'
import { useAddress } from '@/features/user/address/hooks/use-address'
import { useUser } from '@/features/user/hooks/use-user'
import { AddressRow } from '@/molecules/address-row/index.react'
import { ModalAddAddress } from '@/molecules/modal-add-address/index.react'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import _ from 'lodash'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

type IdentityAddressesProps = React.ComponentPropsWithoutRef<'div'>

export const IdentityAddresses = ({ ...props }: IdentityAddressesProps) => {
  const { t } = useTranslation(['pass'])

  const { id, company } = useCompanyContext()
  const { useUserProfile } = useUser()
  const { data: user, isLoading: isLoadingUser } = useUserProfile()
  const addresses = id ? company?.Address ?? [] : user?.naturalPerson.Address ?? null

  const [modalAddAddress, setModalAddAddress] = useState<boolean>(false)

  const { createAddressMutation } = useAddress()
  const { mutateAsync: createAddress } = createAddressMutation()

  const navigate = useNavigate()

  return <>
    <ModalAddAddress
      open={modalAddAddress}
      onClose={() => setModalAddAddress(false)}
      onValidated={async (address) =>
        await createAddress({ userUUID: user?.naturalPerson?.UUID || '', address })
          .then(() => toast.success(t('success.create')))
          .catch(() => toast.error(t('error.create')))
          .finally(() => setModalAddAddress(false))
      }
    />
    <div
      className={twMerge('pt-4 mb-6 md:mb-0 2xl:mb-6 md:py-4', props.className)}
      {..._.omit(props, 'className')}
    >
      <div className="flex justify-between md:px-6 mb-5">
        <Base className="text-slate-300 hidden md:block">
          {t('olkypass.accountPage.basicInfo.tabs.adresses')}
        </Base>
        <A onClick={() => navigate(getRoute(Routes.account_alias_address))}>
          {t('olkypass.general.see_all')}
        </A>
      </div>
      <div className={'mb-4'}>
        {addresses &&
          addresses.slice(0, 3).map((address, index) => <AddressRow
              address={address}
              key={index}
              className={''}
              // @TODO Ask Xavier to add a main residence boolean in the 6SO data
              home={
                id
                  ? address.UUID === _.first(addresses).UUID
                  : address.Tag === 'Résidence principale'
              }
            />)}
      </div>

      {isLoadingUser && [0, 1, 2].map((i) => <Skeletton key={i} className={'mx-6 mb-1'} />)}
      {!id && false && <div
          className="flex space-x-1 items-center md:px-6 md:pb-2 pb-4"
          onClick={() => setModalAddAddress(true)}
        >
          <PlusCircleIcon className="h-6 w-6 text-blue-500" />
          <A>{t('olkypass.accountPage.basicInfo.button.add_address')}</A>
        </div>}
    </div>
  </>
}
