import Column from '@/atoms-react/column/Column.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import { useKYC } from '@/features/kyc/hooks/use-kyc'
import { KYCDocumentType } from '@/features/kyc/types'
import React, { useState } from 'react'
import { ref } from 'vue'
import { getLanguage } from '@/features/utils/i18n.ts'

type DocumentTypeSelectorProps = {
  onDocumentSelected: (documentType: KYCDocumentType) => void
} & React.ComponentPropsWithoutRef<'div'>

export const DocumentTypeSelector = ({
  onDocumentSelected,
  ...props
}: DocumentTypeSelectorProps) => {
  const userLanguage = ref(getLanguage())
  const { KYCDocumentTypesQuery } = useKYC()
  const { data: KYCDocuments, isLoading: isLoadingKYCDocuments } = KYCDocumentTypesQuery()
  const [selectedDocumentTypeID, setSelectedDocumentTypeID] = useState<number | null>(null)

  const onSelectedDocumentChange = (id: number) => {
    if (!id) return

    const document = KYCDocuments?.find((doc) => doc.id === id)
    if (!document) return

    setSelectedDocumentTypeID(id)
    onDocumentSelected(document)
  }

  return (
    <Column {...props}>
      <div className="grow flex flex-col justify-between">
        <div className={'mb-1'}>
          {!isLoadingKYCDocuments && (
            <Dropdown
              className={'mb-6'}
              value={selectedDocumentTypeID ? selectedDocumentTypeID.toString() : null}
              onChange={(selected) => onSelectedDocumentChange(parseInt(selected.key))}
              items={
                KYCDocuments?.map((document) => ({
                  value: userLanguage.value === 'fr' ? document.type_fr : document.type_en,
                  key: document.id.toString(),
                  groupKey: document.need_other_name ? '1' : '0'
                })) ?? []
              }
              hideUngrouped
              groups={[{ key: '0' }, { key: '1' }]}
            />
          )}
        </div>
      </div>
    </Column>
  )
}
