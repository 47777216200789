export type JwtType = {
  token: string | null
  refresh: string | null
}

export type ResetPasswordRequest = {
  password: string
  confirm_password: string
  id: string
  token: string
  language: string
  captcha_token?: string
}

export type CredentialInterface = {
  id: number
  user_id: number
  username: string
  device_name: string
  domain_name: string
  credential_id: string
  credential_key: any
  signatures: any
}

export type CheckEmailExistsResponse = {
  existStatus: boolean
  is2faActivated: boolean
}

export type AuthenticateWebAuthnFullResponse = {
  login: boolean
  token: string
  signing_action_id: string
}

export type AuthenticateWebAuthnResponse = string | AuthenticateWebAuthnFullResponse

export type CheckLoginResponse = {
  login: boolean
  two_fa_required: boolean
  token: string
}

export type LoginResponse = {
  token: string
}
export enum Gender {
  Man = 'Mr',
  Woman = 'Mme'
}

export enum GenderDTO {
  Man = 'Mr',
  Woman = 'Mme'
}

export type PartnerMutationResponse = {
  name: string
  email: string
}

export type MirrorMutationResponse = {
  data?: {
    uuid: string
  }
}
