import { Modal, ModalContent } from '@/atoms-react/modal/index.react'
import { SolidAuthnForm } from './sollid-authn-process.react'
import Loader from '@/atoms-react/loader/Loader.react'
import { useState, useEffect } from 'react'
import { getRoute } from '@/app/olkypass/utils/routes.ts'
import { Routes } from '@/features/router.ts'
import { useNavigate } from 'react-router-dom'
import { WebAuthnRegisterApi } from '@/features/auth/api-client/webauthn-register-api-client'
import { SecurityApiClient } from '@/features/security/api-client/api-client.ts'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

type SolidAuthnModalProps = {
  action: string
  onClose: () => void
  onValidated: () => void
}

export const SolidAuthnModal = ({ action, onClose, onValidated }: SolidAuthnModalProps) => {
  const { t } = useTranslation(['pass'])
  const [activateSolidAuthnModal, setActivateSolidAuthnModal] = useState<boolean>(false)

  useEffect(() => {
    console.log('Check if modal active')
    checkActiveSolidAuthn()
  }, [activateSolidAuthnModal])

  const navigate = useNavigate()
  const redirectToSecurity = () =>
    navigate(getRoute(Routes.security), {
      replace: true
    })

  const checkActiveSolidAuthn = async () => {
    const is2faActive = await SecurityApiClient.is2faActive()
    const isWebAuthnActive = (await WebAuthnRegisterApi.checkActivation())?.isWebAuthnActivated
    if (!isWebAuthnActive && !is2faActive) {
      console.log('Call to redirect to security page')
      toast.error(t('olkypass.securityPage.notification'))
      redirectToSecurity()
      onClose()
      setActivateSolidAuthnModal(false)
    } else {
      setActivateSolidAuthnModal(true)
    }
  }

  return <Modal
    className={'sm:m-0 m-0 sm:w-full md:w-9/12'}
    maxWidthClassName={'max-w-xl'}
    open={true}
    onClose={onClose}
  >
    <ModalContent title={''}>
      {!activateSolidAuthnModal && <Loader center />}
      {activateSolidAuthnModal && <SolidAuthnForm action={action} onValidated={onValidated} />}
    </ModalContent>
  </Modal>
}
