import { getRoute } from '@/app/olkypass/utils/routes'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { setJwt } from '@/features/auth/utils'
import { Routes } from '@/features/router'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { animatedNext } from '../layout/AnimatedForm.react'
import { DocumentsGateway, DocumentsGatewayAction } from './documents/index.react'
import { AuthenticateOutput } from './login/authenticate/Authenticate.react'
import LoginRoot from './login/index.react'
import SignupRoot, { SignupOutput } from './signup/index.react'
import AccountWelcome, { AccountWelcomeOutput } from './welcome/index.react'

export type LoginInfo = {
  welcome: AccountWelcomeOutput | null
  login: AuthenticateOutput | null
  signup: SignupOutput | null
}

export const Gateways = () => {
  const { block, step, session_uuid } = useParams()
  const { refresh, createMirrorIdentity } = useAuth()
  const navigate = useNavigate()

  const [gatewaysData, setGatewaysInfo] = useState<LoginInfo>({
    welcome: null,
    login: null,
    signup: null
  })

  useEffect(() => {
    // Special case for now for reset password
    if (block === 'login' && step === 'reset') {
      return
    }

    // Redirect to welcome if invalid block or just reloaded the page now
    if (
      (gatewaysData as any)[block || ''] !== undefined ||
      !session_uuid ||
      !gatewaysData.welcome?.email
    ) {
      navigate(getRoute(Routes.flow_block, { session_uuid, block: 'welcome' }), {
        replace: true
      })
      return
    }
  }, [])

  return (
    <>
      {block === 'documents' && (
        <DocumentsGateway
          action={DocumentsGatewayAction.KYCDocument}
          onValidated={() => refresh(true)}
        />
      )}

      {block === 'signup' && (
        <SignupRoot
          input={{ email: gatewaysData.welcome?.email || '' }}
          onValidated={() => {
            if (localStorage.getItem('partner_uuid')) {
              createMirrorIdentity(localStorage.getItem('partner_uuid'))
              localStorage.removeItem('partner_uuid')
            }
            refresh(true)
          }}
        />
      )}
      {block === 'login' && (
        <LoginRoot
          input={{
            email: gatewaysData.welcome?.email || '',
            is2faActivated: gatewaysData.welcome?.is2faActivated || false
          }}
          onValidated={(jwt) => {
            setJwt(jwt)
            if (localStorage.getItem('partner_uuid')) {
              createMirrorIdentity(localStorage.getItem('partner_uuid'))
              localStorage.removeItem('partner_uuid')
            }
            refresh(true)
          }}
        />
      )}
      {block === 'welcome' && (
        <AccountWelcome
          onValidated={(welcome) =>
            animatedNext('forward', async () => {
              setGatewaysInfo((i) => ({ ...i, welcome }))
              if (welcome.emailExists) {
                navigate(getRoute(Routes.flow_block, { session_uuid, block: 'login' }))
              } else {
                navigate(getRoute(Routes.flow_block, { session_uuid, block: 'signup' }))
              }
            })
          }
        />
      )}
    </>
  )
}
