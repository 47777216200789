import { Button } from '@/atoms-react/button/Button.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { useKypay } from '@/features/kypay/hooks/use-kypay'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatCurrencyInput, formatNumber } from '@/features/utils/functions.ts'
import { Checkbox } from '@/atoms-react/input-checkbox/index.react.tsx'
import { Base } from '@/atoms-react/text/Text.react.tsx'
import { RadioCard } from '@/atoms-react/radio-card/index.react.tsx'
import CopyButton from '@/app/olkypass/views/kypay/components/button-copy/index.react.tsx'
import toast from 'react-hot-toast'
import { useUser } from '@/features/user/hooks/use-user-vue.ts'
enum ModalFeedKypayStep {
  STEP_1,
  STEP_2
}
enum PaymentTools {
  CARD = 'CARD',
  BANK_TRANSFER = 'BANK_TRANSFER'
}
export const ModalFeedBank = ({ currentAccount, ...props }: ModalProps) => {
  const { t } = useTranslation(['wallet'])
  const { user } = useUser()
  const [amountString, setAmountString] = useState<string>('0,00')
  const [amount, setAmount] = useState<number | null>(null)
  const [acceptCGU, setAcceptCGU] = useState<boolean>(false)
  const [operationInProgress, setOperationInProgress] = useState<boolean>(false)
  const [step, setStep] = useState<ModalFeedKypayStep>(ModalFeedKypayStep.STEP_1)
  const [selectedPaymentTools, setSelectedPaymentTools] = useState<string>(
    PaymentTools.BANK_TRANSFER
  )
  const { feedKypayBalanceMutation } = useKypay()
  const { mutateAsync: feedKypayBalance } = feedKypayBalanceMutation()
  const resetInputs = () => {
    setAmount(0)
    setAmountString(formatNumber(0))
  }
  const inputRef = useRef<HTMLInputElement | null>()

  const forceCursorPosition = () => {
    if (inputRef.current) {
      const inputLength = inputRef.current.value.length
      inputRef.current.setSelectionRange(inputLength, inputLength) // Force cursor to the end on focus
    }
  }
  const handleNextStepEvent = async () => {
    if (selectedPaymentTools === 'CARD') {
      //
      if (amount) {
        setOperationInProgress(true)
        try {
          const response = await feedKypayBalance({
            amount: amount * 100,
            description: 'Alimenter mon solde'
          })
          window.open(response.url, '_blank')
          // if (!success) document.location = response.url
          setOperationInProgress(false)
          resetInputs()
          props.onClose?.()
        } catch (e) {
          toast.error(t('kypay.error.feed_kypay_balance'))
        }
        setOperationInProgress(false)
      }
    } else {
      setStep(ModalFeedKypayStep.STEP_2)
    }
  }
  useEffect(() =>{ forceCursorPosition()}, [amountString])

  useEffect(() => {
    if (props.open) {
      setAcceptCGU(false)
      setSelectedPaymentTools(PaymentTools.BANK_TRANSFER)
      setStep(ModalFeedKypayStep.STEP_1)
    }
  }, [props.open])
  return (
    <Modal
      className="sm:w-full w-full max-w-xl"
      {...props}
      onClose={() => {
        resetInputs()
        props.onClose?.()
      }}
    >
      {step === ModalFeedKypayStep.STEP_1 && (
        <ModalContent title={t('kypay.modal_deposit_funds.steps.step_1.header')}>
          <div className={'mt-4 opacity-1'}>
            {/*grid grid-cols-1 md:grid-cols-2 gap-2*/}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/*col-span-1 md:col-span-2*/}
              <div className="col-span-1 md:col-span-2">
                <Checkbox value={acceptCGU} onChange={(value) => setAcceptCGU(value)}>
                  <Base className="mb-1">
                    {t('kypay.modal_deposit_funds.steps.step_1.accept_cgu.part_1')}&nbsp;
                    <a
                      href="https://www.olky.eu/files/pay/cgu-kypay.pdf?1.0.1"
                      target="_blank"
                      className={'text-sky-500'}
                    >
                      {t('kypay.modal_deposit_funds.steps.step_1.accept_cgu.part_2')}
                    </a>
                    &nbsp;
                    {t('kypay.modal_deposit_funds.steps.step_1.accept_cgu.part_3')}
                  </Base>
                </Checkbox>
              </div>
              <div className="col-span-1 md:col-span-2">
                <InputLabel
                  value={amountString}
                  onClick={forceCursorPosition}
                  onChange={(value: string) => {
                    const formattedValue = formatCurrencyInput(value)
                    const parsed = parseFloat(formattedValue)
                    if (!isNaN(parsed)) {
                      setAmount(parsed)
                      setAmountString(formatNumber(parsed))
                    }
                  }}
                  suffixIcon={() => <span>€</span>}
                  type="text"
                  min="0.00"
                  max="10000.00"
                  step="0.01"
                  label={t('kypay.modal_transfer_fund.input.amount_label')}
                  placeholder={t('kypay.modal_transfer_fund.input.amount_placeholder')}
                  ref={inputRef}
                />
              </div>
              <div>
                <RadioCard
                  className="items-center rounded-tl-lg rounded-br-lg min-h-full"
                  active={selectedPaymentTools === PaymentTools.BANK_TRANSFER}
                  onClick={() => setSelectedPaymentTools(PaymentTools.BANK_TRANSFER)}
                >
                  <Base>
                    {t('kypay.modal_deposit_funds.steps.step_1.payment_methods.bank_transfer')}
                  </Base>
                  <div className={'mt-3 flex flex-col gap-2'}>
                    <div>
                      <img src="/images/olkypass/feeds/SEPA.png" alt="SEPA" />
                    </div>
                    <div>
                      <img src="/images/olkypass/feeds/SEPA_INSTANT_FR.png" alt="SEPA" />
                    </div>
                  </div>
                </RadioCard>
              </div>
              <div>
                {currentAccount?.is_principal_account && (
                  <>
                    <RadioCard
                      className="items-center rounded-tl-lg rounded-br-lg min-h-full"
                      active={selectedPaymentTools === PaymentTools.CARD}
                      onClick={() => setSelectedPaymentTools(PaymentTools.CARD)}
                    >
                      <Base>
                        {t('kypay.modal_deposit_funds.steps.step_1.payment_methods.card')}
                      </Base>
                      <div className={'mt-3 flex gap-2'}>
                        <img src="/images/olkypass/feeds/VISA.png" alt="VISA" />
                        <img src="/images/olkypass/feeds/CB.png" alt="CB" />
                      </div>
                    </RadioCard>
                  </>
                )}
                {!currentAccount?.is_principal_account && (
                  <>
                    <div
                      data-tooltip={t('kypay.modal_deposit_funds.steps.step_1.coming_soon')}
                      className={'h-full'}
                    >
                      <RadioCard
                        className="items-center rounded-tl-lg rounded-br-lg min-h-full"
                        disabled={true}
                      >
                        <Base>
                          {t('kypay.modal_deposit_funds.steps.step_1.payment_methods.card')}
                        </Base>
                        <div className={'mt-3 flex gap-2'}>
                          <img src="/images/olkypass/feeds/VISA.png" alt="VISA" />
                          <img src="/images/olkypass/feeds/CB.png" alt="CB" />
                        </div>
                      </RadioCard>
                    </div>
                  </>
                )}
              </div>
            </div>

            <Button
              disabled={!amount || !amountString || !acceptCGU}
              className={'mt-6'}
              loading={operationInProgress}
              onClick={handleNextStepEvent}
            >
              {t('button.continue')}
            </Button>
          </div>
        </ModalContent>
      )}
      {step === ModalFeedKypayStep.STEP_2 && (
        <ModalContent title={t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.header')}>
          <div className={'mt-4 opacity-1'}>
            <div className={'flex'}>
              <Base className={'text-sky-500 text-center'}>
                {t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.message')}
              </Base>
            </div>
            <div className="grid grid-cols-4 gap-4 text-center mt-4 mb-4">
              <div> {t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.labels.account')}</div>
              <div className={'col-span-2'}>
                <Base className="font-bold">
                  {user.user?.naturalPerson.FirstName + ' ' + user.user?.naturalPerson.LastName}
                </Base>
              </div>
              <div>
                <CopyButton
                  text={currentAccount.partner_name}
                  iconClassName={'text-sky-500'}
                  succesMessage={
                    t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.labels.account') +
                    ' ' +
                    t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.copied_item')
                  }
                />
              </div>

              <div> {t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.labels.iban')}</div>
              <div className={'col-span-2'}>
                <Base className="font-bold">{currentAccount?.virtual_iban?.IBAN}</Base>
              </div>
              <div>
                <CopyButton
                  text={currentAccount?.virtual_iban?.IBAN}
                  iconClassName={'text-sky-500'}
                  succesMessage={
                    t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.labels.iban') +
                    ' ' +
                    t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.copied_item')
                  }
                />
              </div>

              <div> {t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.labels.bic')}</div>
              <div className={'col-span-2'}>
                <Base className="font-bold">{currentAccount?.virtual_iban?.BIC}</Base>
              </div>
              <div>
                <CopyButton
                  text={currentAccount?.virtual_iban?.BIC}
                  iconClassName={'text-sky-500'}
                  succesMessage={
                    t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.labels.bic') +
                    ' ' +
                    t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.copied_item')
                  }
                />
              </div>

              <div> {t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.labels.amount')}</div>
              <div className={'col-span-2'}>
                <Base className="font-bold">{amountString} €</Base>
              </div>
              <div>
                <CopyButton
                  text={amountString}
                  iconClassName={'text-sky-500'}
                  succesMessage={
                    t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.labels.amount') +
                    ' ' +
                    t('kypay.modal_deposit_funds.steps.step_2.bank_transfer.copied_item')
                  }
                />
              </div>
            </div>
          </div>
        </ModalContent>
      )}
    </Modal>
  )
}
