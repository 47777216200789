import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import { useUser } from '@/features/user/hooks/use-user'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../../../_layout/index.react'
import { PhoneCard } from '@/molecules/phone-card/index.react'
import useCreatePhone from '@/features/user/phone/hooks/use-create-phone'
import { ModalAddMobile } from '@/molecules/modal-add-mobile/index.react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Routes } from '@/features/router'
import { ModalDeleteAlias } from '@/molecules/modal-delete-alias/index.react'
import { Base } from '@/atoms-react/text/Text.react'
import { useDeleteAlias } from '@/features/user/hooks/use-delete-alias'
import { AliasMobile } from '@/features/user/types'
import { NaturalEntityDataType } from '@/features/utils/types'

type AliasesMobileProps = React.ComponentPropsWithoutRef<'div'>

export const AliasesMobileApp = ({ ...props }: AliasesMobileProps) => {
  const { t } = useTranslation(['pass'])
  const { useUserProfile } = useUser()
  const { data: user, refetch } = useUserProfile()

  const mobiles = user?.naturalPerson.AliasMobile
  const [searchParams] = useSearchParams()

  const [modalAddMobile, setModalAddMobile] = useState<boolean>(!!searchParams.get('add'))
  const [deletedMobile, setDeletedMobile] = useState<AliasMobile | null>(null)

  const { mutateAsync: createPhone } = useCreatePhone()
  const { mutateAsync: deleteAlias } = useDeleteAlias()
  const navigate = useNavigate()

  const onDelete = async () => {
    if (!deletedMobile) {
      return
    }
    await deleteAlias({
      ItemType: NaturalEntityDataType.AliasMobileDto,
      ItemUUID: deletedMobile.UUID || '',
      UUID: user?.naturalPerson?.UUID || ''
    })
    await refetch()
    setDeletedMobile(null)
  }

  return <Column {...props}>
    <LayoutTitle
      action={
        <Button onClick={() => setModalAddMobile(true)} icon={PlusCircleIcon}>
          {t('aliasMobilePage.button.add_mobile')}
        </Button>
      }
    >
      {t('aliasMobilePage.title')}
    </LayoutTitle>

    <ModalAddMobile
      open={modalAddMobile}
      onClose={() => setModalAddMobile(false)}
      onValidated={async (mobile) =>
        await createPhone({ ...mobile, UUID: mobile.UUID || '' })
          .then(() => toast.success(t('success.create')))
          .catch(() => toast.error(t('error.create_mobile')))
          .finally(() => setModalAddMobile(false))
      }
    />
    <ModalDeleteAlias
      isOpen={!!deletedMobile}
      onClose={() => setDeletedMobile(null)}
      onValidated={onDelete}
    >
      <Base>{t('aliasMobilePage.confirm_delete', { name: deletedMobile?.MobileNumber })}</Base>
    </ModalDeleteAlias>
    <div className={'flex flex-col max-w-3xl'}>
      {mobiles &&
        mobiles?.map((mobile) => <PhoneCard
            key={mobile.UUID}
            onPublicClick={() => navigate(Routes.account_alias_public)}
            phone={mobile}
            onDelete={() => setDeletedMobile(mobile)}
          />)}
    </div>
  </Column>
}
