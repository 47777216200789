import { Base } from '@/atoms-react/text/Text.react'
import { AliasIBAN } from '@/features/user/types'
import { CreditCardIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { AliasCard } from '../alias-card/index.react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@/atoms-react/tooltip/Tooptip.react.tsx'
import { ShieldCheckIcon, ShieldExclamationIcon } from '@heroicons/react/24/solid'

type IbanCardProps = {
  iban: AliasIBAN
  isLoading?: boolean
  onClick?: () => void
  onDelete?: () => void
  onPublicClick?: () => void
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onClick'>

export const IbanCard = ({ iban, ...props }: IbanCardProps) => {
  const { t } = useTranslation(['pass'])
  return (
    <AliasCard
      tooltipDesignation={t('olkypass.aliasPublicPage.this_iban')}
      icon={CreditCardIcon}
      isPublic={iban?.Public}
      {...props}
    >
      <>
        <Base
          className={twMerge('md:mr-3 font-normal text-ellipsis overflow-hidden break-normal')}
          title={`${iban.BankInfoDto.Institution} ${iban.BankInfoDto.Institution}`}
        >
          {`${iban.BankInfoDto.Institution} ${iban.BankInfoDto.Institution}`}
        </Base>
        <Base
          className={twMerge('md:mr-3 font-semibold text-ellipsis overflow-hidden break-normal')}
          title={`${iban.Iban}`}
        >
          {`${iban.Iban}`}
        </Base>
        <Base
          className={twMerge(
            'md:mr-3 font-semibold text-gray-500 text-ellipsis overflow-hidden break-normal'
          )}
          title={`BIC ${iban.BIC}`}
        >
          {`BIC ${iban.BIC}`}
        </Base>

        <div className={'flex items-center w-fit'}>
          <Base className={twMerge('text-sm text-slate-500 mr-1')}>{t('atoms.card.verify')} -</Base>

          <div className={twMerge('flex items-center')}>
            <Base className={twMerge('text-sm w-max mr-1 underline', 'text-slate-500')}>
              {iban?.valid
                ? t('atoms.iban_card.is_verified')
                : t('atoms.iban_card.is_not_verified')}
            </Base>

            <Tooltip />
            <div
              data-tooltip={
                iban?.valid
                  ? t('atoms.iban_card.is_valid', { name: '' })
                  : t('atoms.iban_card.is_invalid', { name: '' })
              }
              data-position="bottom"
            >
              {iban?.valid && <ShieldCheckIcon className={`h-4 w-4 text-green-500`} />}
              {!iban?.valid && <ShieldExclamationIcon className={`h-4 w-4 text-orange-500`} />}
            </div>
          </div>
        </div>
      </>
    </AliasCard>
  )
}
